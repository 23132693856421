import { type AxiosResponse } from 'axios';

import {
  type DtoBlockCountResponse,
  type DtoBlockDoc,
  type DtoBlockListResp,
  type DtoBlockResp,
  type DtoBlockSearchResponse,
  type DtoCreateBlockVoteRequest,
  type DtoGetBlockDataEntryResponse,
  type DtoGetBlockVoteResponse,
  type DtoRenderBlocksRequest,
  type DtoUpdatePuzzleSliceJobRequest,
  type EnumsBlockSearchSortBy,
  type EnumsBrandPredefinedBlockScenario,
} from '@lp-lib/api-service-client/public';
import {
  type Block,
  type BlockAction,
  type BlockFields,
  type BlockType,
  type VideoEffectsSettingsBlock,
} from '@lp-lib/game';

import { type BlockSettings } from '../../types';
import { BaseAPIService } from './base.api';
import { Paginator } from './pagination';

export enum BlockUpdateOperation {
  SWAP = 'swap',
  MOVE = 'move',
}

export interface CreateGameBlockRequest {
  position: number;
  type: BlockType;
}

export interface UpdateFieldReq<
  B extends Block,
  T extends keyof BlockFields<B>
> {
  field: T;
  value: BlockFields<B>[T];
}

export interface SwapBlocksReq {
  operation: BlockUpdateOperation;
  gameId: string;
  dragPosition: number;
  targetPosition: number;
}

export interface BlocksResp {
  blocks: Block[];
}

export interface BlockResp {
  block: Block;
}

export interface UpdateOutdatedRecordingReq {
  outdatedRecording: boolean;
}

export interface UpdateRecordingMediaIdReq {
  mediaId: string | null;
}

export interface AddBlockRecordingReq {
  mediaId: string | null;
  actions: BlockAction[];
  durationMs: number;
  version: number;
}

export type BlockSearchParams = {
  type?: BlockType | null;
  gameId?: string | null;
  brandIds?: string | null;
  includeBrands?: boolean;
  sort?: EnumsBlockSearchSortBy | null;
  all?: boolean;
};

export class BlockAPI extends BaseAPIService {
  createStandaloneBlock(req: {
    type: BlockType;
    scenario?: EnumsBrandPredefinedBlockScenario | null;
  }): Promise<AxiosResponse<BlockResp>> {
    return this.client.post<BlockResp>(`/blocks`, req);
  }

  createGameBlock(
    gameId: string,
    req: CreateGameBlockRequest
  ): Promise<AxiosResponse<BlocksResp>> {
    return this.client.post<BlocksResp>(`/games/${gameId}/blocks`, req);
  }

  duplicate(blockId: string): Promise<AxiosResponse<DtoBlockResp>> {
    return this.client.post(`/blocks/${blockId}/duplicate`);
  }

  duplicateGameBlock(
    gameId: string,
    blockId: string
  ): Promise<AxiosResponse<BlocksResp>> {
    return this.client.post<BlocksResp>(
      `/games/${gameId}/blocks/${blockId}/duplicate`
    );
  }

  duplicateGameBlocks(
    gameId: string,
    targetGameId: string,
    blockIds?: string[]
  ): Promise<AxiosResponse<BlocksResp>> {
    return this.client.post<BlocksResp>(`/games/${gameId}/blocks/duplicate`, {
      targetGameId: targetGameId,
      blockIds: blockIds,
    });
  }

  delete(blockId: string): Promise<AxiosResponse> {
    return this.client.delete(`/blocks/${blockId}`);
  }

  detachGameBlock(
    gameId: string,
    blockId: string
  ): Promise<AxiosResponse<BlocksResp>> {
    return this.client.delete(`/games/${gameId}/blocks/${blockId}`);
  }

  getBlocksByGameId(gameId: string): Promise<AxiosResponse<BlocksResp>> {
    return this.client.get<BlocksResp>(`/games/${gameId}/blocks`);
  }

  getBlock(blockId: string): Promise<AxiosResponse<DtoBlockResp>> {
    return this.client.get<DtoBlockResp>(`/blocks/${blockId}`);
  }

  updateField<
    B extends Block,
    T extends keyof BlockFields<B> = keyof BlockFields<B>
  >(
    blockId: string,
    req: UpdateFieldReq<B, T>
  ): Promise<AxiosResponse<Partial<BlockFields<B>>>> {
    return this.client.put<Partial<BlockFields<B>>>(
      `/blocks/${blockId}/field`,
      req
    );
  }

  updateFieldV2<
    B extends Block,
    T extends keyof BlockFields<B> = keyof BlockFields<B>
  >(
    block: B,
    field: T,
    value: BlockFields<B>[T]
  ): Promise<AxiosResponse<Partial<BlockFields<B>>>> {
    return this.client.put<Partial<BlockFields<B>>>(
      `/blocks/${block.id}/field`,
      {
        field,
        value,
      }
    );
  }

  moveBlocks(req: SwapBlocksReq): Promise<AxiosResponse<BlocksResp>> {
    return this.client.post<BlocksResp>('/blocks/move', req);
  }

  updateOutdatedRecording(
    blockId: string,
    req: UpdateOutdatedRecordingReq
  ): Promise<AxiosResponse<number>> {
    return this.client.put<number>(
      `/blocks/${blockId}/outdated-recording`,
      req
    );
  }

  addRecording(
    blockId: string,
    req: AddBlockRecordingReq
  ): Promise<AxiosResponse<void>> {
    return this.client.post<void>(`/blocks/${blockId}/recording`, req);
  }

  deleteRecording(blockId: string): Promise<AxiosResponse<number>> {
    return this.client.delete<number>(`/blocks/${blockId}/recording`);
  }

  updateRecordingMediaId(
    blockId: string,
    req: UpdateRecordingMediaIdReq
  ): Promise<AxiosResponse<number>> {
    return this.client.put<number>(`/blocks/${blockId}/recording-mediaId`, req);
  }

  getBlockSettings<T extends BlockSettings>(
    type: BlockType
  ): Promise<AxiosResponse<T | null>> {
    return this.client.get<T | null>(`/block-settings/${type}`);
  }

  updateBlockSettings<T extends BlockSettings>(
    type: BlockType,
    data: T
  ): Promise<AxiosResponse<void>> {
    return this.client.post<void>(`/block-settings/${type}`, data);
  }

  updateVideoEffectsSettings(
    blockId: string,
    req: VideoEffectsSettingsBlock
  ): Promise<AxiosResponse<void>> {
    return this.client.put<void>(
      `/blocks/${blockId}/video-effects-settings`,
      req
    );
  }

  searchBlocks(
    q: string,
    params?: BlockSearchParams
  ): Paginator<DtoBlockSearchResponse, DtoBlockDoc> {
    return new Paginator<DtoBlockSearchResponse, DtoBlockDoc>(
      this.client,
      '/blocks/search',
      'blockSearchResults',
      {
        size: 36,
        config: {
          params: { q, ...params },
        },
      }
    );
  }

  count(): Promise<AxiosResponse<DtoBlockCountResponse>> {
    return this.client.get(`/blocks/count`);
  }

  getBlockVote(
    blockId: string
  ): Promise<AxiosResponse<DtoGetBlockVoteResponse>> {
    return this.client.get(`/blocks/${blockId}/vote`);
  }

  addBlockVote(
    blockId: string,
    req: DtoCreateBlockVoteRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.post<void>(`/blocks/${blockId}/vote`, req);
  }

  refreshData(
    blockId: string
  ): Promise<AxiosResponse<DtoGetBlockDataEntryResponse>> {
    return this.client.put(`/blocks/${blockId}/refresh-data`);
  }

  getData(
    blockId: string
  ): Promise<AxiosResponse<DtoGetBlockDataEntryResponse>> {
    return this.client.get(`/blocks/${blockId}/data`);
  }

  updatePuzzleSliceJob(
    blockId: string,
    req: DtoUpdatePuzzleSliceJobRequest
  ): Promise<AxiosResponse<BlockResp>> {
    return this.client.put(`/blocks/${blockId}/puzzle-slice-job`, req);
  }
  deletePuzzleSliceJob(blockId: string): Promise<AxiosResponse<void>> {
    return this.client.delete(`/blocks/${blockId}/puzzle-slice-job`);
  }
  renderBlocks(
    req: DtoRenderBlocksRequest
  ): Promise<AxiosResponse<DtoBlockListResp>> {
    return this.client.post<DtoBlockListResp>(`/blocks/render`, req);
  }
}
