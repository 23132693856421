import { type AxiosResponse } from 'axios';

import { type QueryPairingRoundsParams } from '@lp-lib/api-service-client/public';

import {
  type GlobalPairingRound,
  type LeaderboardTeam,
  type Pairing,
  type PairingGame,
  type PairingParticipation,
  type PairingParticipationStats,
  type PairingPlayer,
  type PairingRepairLog,
  type PairingRound,
  type PairingRoundStatus,
  type ParticipationMode,
  type PublicGlobalPairingRound,
} from '../../types';
import { BaseAPIService } from './base.api';
import { type Paginable, Paginator } from './pagination';

export interface CreateGlobalPairingRoundRequest {
  mainGamePackId: string;
  shadowGamePackIds?: string[];
  asLevels?: boolean;
  startedAt: string;
  endedAt: string;
  messageCampaignId: string | null;
  orgIds: string[] | null;
  participationMode: ParticipationMode;
}

type UpdateGlobalPairingRoundRequest = Partial<CreateGlobalPairingRoundRequest>;

export interface GlobalPairingRoundResponse {
  globalRound: GlobalPairingRound;
}

export interface GlobalPairingRoundsResponse extends Paginable {
  globalRounds: GlobalPairingRound[];
}

export interface PublicGlobalPairingRoundsResponse extends Paginable {
  globalRounds: PublicGlobalPairingRound[];
}

export interface PairingRepairLogsResponse {
  logs: PairingRepairLog[];
}

export enum MyPairingsQueryType {
  Active = 'active',
  All = 'all',
  Round = 'round',
}

export enum LeaderboardQueryType {
  Global = 'global',
  Org = 'org',
}

export interface UpdatePairingRoundRequest {
  status?: PairingRoundStatus;
}

export interface CreatePairingRoundRequest extends UpdatePairingRoundRequest {
  orgId: string;
}

export interface PairingRoundResponse {
  round: PairingRound;
}

export interface PairingRoundsResponse extends Paginable {
  rounds: PairingRound[];
}

export interface PairingResponse {
  pairing: Pairing;
}

export interface PairingsResponse extends Paginable {
  pairings: Pairing[];
}

export interface LeaderboardSummaryResponse {
  round: PairingRound;
  globalCompanyRank?: number;
}

export interface LeaderboardTeamsResponse extends Paginable {
  teams: LeaderboardTeam[];
}

export interface UpdatePairingRequest {
  organizerUid?: string;
}

export interface PlayerResponse {
  player: PairingPlayer;
}

export interface CreatePairingPlayerRequest {
  uid: string;
}

export interface GetMyPairingsParams {
  type: MyPairingsQueryType;
  roundId?: string;
  withRank?: boolean;
}

export interface MyStatsSummaryResponse {
  pairsRoundsPlayed: number;
}

export interface PairingGameResponse {
  pairingGame: PairingGame;
}

export interface PairingRepairLogsResponse {
  logs: PairingRepairLog[];
}

export interface ParticipationStatsResponse {
  stats: PairingParticipationStats;
}

export interface ParticipationDetailResponse {
  items: PairingParticipation[];
}

export class PairingAPI extends BaseAPIService {
  createGlobalRound(
    req: CreateGlobalPairingRoundRequest
  ): Promise<AxiosResponse<GlobalPairingRoundResponse>> {
    return this.client.post('/global-pairing-rounds', req);
  }

  deleteGlobalRound(globalRoundId: string): Promise<AxiosResponse> {
    return this.client.delete(`/global-pairing-rounds/${globalRoundId}`);
  }

  updateGlobalRound(
    globalRoundId: string,
    req: UpdateGlobalPairingRoundRequest
  ): Promise<AxiosResponse<GlobalPairingRoundResponse>> {
    return this.client.put(`/global-pairing-rounds/${globalRoundId}`, req);
  }

  getGlobalRoundById(
    globalRoundId: string
  ): Promise<AxiosResponse<GlobalPairingRoundResponse>> {
    return this.client.get(`/global-pairing-rounds/${globalRoundId}`);
  }

  queryGlobalRounds(): Promise<AxiosResponse<GlobalPairingRoundsResponse>> {
    return this.client.get(`/global-pairing-rounds`);
  }

  upcomingGlobalRounds(): Promise<
    AxiosResponse<PublicGlobalPairingRoundsResponse>
  > {
    return this.client.get(`/global-pairing-rounds/upcoming`);
  }

  repairGlobalRound(globalRoundId: string): Promise<AxiosResponse> {
    return this.client.post(`/global-pairing-rounds/${globalRoundId}/repair`);
  }

  queryGlobalRoundRepairLogs(
    globalRoundId: string
  ): Promise<AxiosResponse<PairingRepairLogsResponse>> {
    return this.client.get(
      `/global-pairing-rounds/${globalRoundId}/repair-logs`
    );
  }

  createRound(
    req: CreatePairingRoundRequest
  ): Promise<AxiosResponse<PairingRoundResponse>> {
    return this.client.post('/pairing-rounds', req);
  }

  deleteRound(roundId: string): Promise<AxiosResponse> {
    return this.client.delete(`/pairing-rounds/${roundId}`);
  }

  updateRound(
    roundId: string,
    req: UpdatePairingRoundRequest
  ): Promise<AxiosResponse<PairingRoundResponse>> {
    return this.client.put(`/pairing-rounds/${roundId}`, req);
  }

  getRoundById(roundId: string): Promise<AxiosResponse<PairingRoundResponse>> {
    return this.client.get(`/pairing-rounds/${roundId}`);
  }

  queryRounds(
    params: QueryPairingRoundsParams
  ): Promise<AxiosResponse<PairingRoundsResponse>> {
    return this.client.get('/pairing-rounds', { params });
  }

  createPairing(roundId: string): Promise<AxiosResponse<PairingResponse>> {
    return this.client.post(`/pairing-rounds/${roundId}/pairings`);
  }

  deletePairing(roundId: string, pairingId: string): Promise<AxiosResponse> {
    return this.client.delete(
      `/pairing-rounds/${roundId}/pairings/${pairingId}`
    );
  }

  updatePairing(
    roundId: string,
    pairingId: string,
    req: UpdatePairingRequest
  ): Promise<AxiosResponse<PairingResponse>> {
    return this.client.put(
      `/pairing-rounds/${roundId}/pairings/${pairingId}`,
      req
    );
  }

  getPairingsByRoundId(roundId: string): Paginator<PairingsResponse, Pairing> {
    return new Paginator<PairingsResponse, Pairing>(
      this.client,
      `/pairing-rounds/${roundId}/pairings`,
      'pairings'
    );
  }

  addPlayer(
    roundId: string,
    pairingId: string,
    req: CreatePairingPlayerRequest
  ): Promise<AxiosResponse<PairingResponse>> {
    return this.client.post(
      `/pairing-rounds/${roundId}/pairings/${pairingId}`,
      req
    );
  }

  removePlayer(
    roundId: string,
    pairingId: string,
    uid: string,
    skipRemoveOrphanedPlayer: boolean
  ): Promise<AxiosResponse<PairingResponse>> {
    return this.client.delete(
      `/pairing-rounds/${roundId}/pairings/${pairingId}/players/${uid}`,
      {
        data: {
          skipRemoveOrphanedPlayer,
        },
      }
    );
  }

  getMyPairings(
    params: GetMyPairingsParams
  ): Promise<AxiosResponse<PairingsResponse>> {
    return this.client.get(`/my/pairings`, {
      params,
    });
  }

  getLeaderboard(
    roundId: string
  ): Promise<AxiosResponse<LeaderboardSummaryResponse>> {
    return this.client.get(`/pairing-rounds/${roundId}/leaderboard`);
  }

  getOrgLeaderboardTeams(
    roundId: string
  ): Paginator<LeaderboardTeamsResponse, LeaderboardTeam> {
    return new Paginator(
      this.client,
      `/pairing-rounds/${roundId}/leaderboard/teams`,
      'teams',
      {
        size: 20,
      }
    );
  }

  getGlobalLeaderboardTeams(
    globalRoundId: string
  ): Paginator<LeaderboardTeamsResponse, LeaderboardTeam> {
    return new Paginator(
      this.client,
      `/global-pairing-rounds/${globalRoundId}/leaderboard/teams`,
      'teams',
      {
        size: 20,
      }
    );
  }

  getGlobalCompanyLeaderboardTeams(
    globalRoundId: string
  ): Paginator<LeaderboardTeamsResponse, LeaderboardTeam> {
    // note: this endpoint does not permit pagination and will only ever return the top 6 teams.
    // we reuse the types here for consistency.
    return new Paginator(
      this.client,
      `/global-pairing-rounds/${globalRoundId}/company-leaderboard/teams`,
      'teams',
      {
        size: 20,
      }
    );
  }

  getMyStatsSummary(): Promise<AxiosResponse<MyStatsSummaryResponse>> {
    return this.client.get(`/my/stats/summary`);
  }

  getPairingGame(
    pairingId: string
  ): Promise<AxiosResponse<PairingGameResponse>> {
    return this.client.get(`/pairings/${pairingId}/game`);
  }

  getGlobalRoundParticipationStats(
    globalRoundId: string
  ): Promise<AxiosResponse<ParticipationStatsResponse>> {
    return this.client.get(
      `/global-pairing-rounds/${globalRoundId}/participation-stats`
    );
  }

  getOrgRoundParticipationStats(
    roundId: string
  ): Promise<AxiosResponse<ParticipationStatsResponse>> {
    return this.client.get(`/pairing-rounds/${roundId}/participation-stats`);
  }

  getOrgRoundParticipationDetail(
    roundId: string
  ): Promise<AxiosResponse<ParticipationDetailResponse>> {
    return this.client.get(`/pairing-rounds/${roundId}/participation-detail`);
  }
}
