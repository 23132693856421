import { type AxiosResponse } from 'axios';

import { type GlobalPairingRound } from '../../types';
import { BaseAPIService } from './base.api';
import { type Paginable } from './pagination';

export interface MessageCampaignsResponse extends Paginable {
  campaigns: MessageCampaign[];
}

export interface MessageCampaignResponse extends Paginable {
  campaign: MessageCampaign;
}

export interface DetailedMessageCampaignResponse extends Paginable {
  campaign: DetailedMessageCampaign;
}

export interface UpdateMessageCampaignRequest {
  name: string;
}

export interface MessageCampaign {
  id: string;
  name: string;
  type: string;
  messageCampaignType: string;
  triggerDescription: string;
  integrationCount: 0;
  createdAt: string;
  updatedAt: string;
}

export interface DetailedMessageCampaign extends MessageCampaign {
  globalPairingRounds: GlobalPairingRound[] | null;
}

export enum MessageCampaignType {
  GlobalRound = 'GlobalRound',
  Tournament = 'Tournament',
  GlobalPromotion = 'GlobalPromotion',
}

export class MessageCampaignAPI extends BaseAPIService {
  query(
    messageCampaignType?: string
  ): Promise<AxiosResponse<MessageCampaignsResponse>> {
    return this.client.get(`/message-campaigns`, {
      params: { messageCampaignType: messageCampaignType },
    });
  }

  delete(campaignId: string): Promise<AxiosResponse> {
    return this.client.delete(`/message-campaigns/${campaignId}`);
  }

  update(
    campaignId: string,
    req: UpdateMessageCampaignRequest
  ): Promise<AxiosResponse> {
    return this.client.put(`/message-campaigns/${campaignId}`, req);
  }

  getCampaignById(
    campaignId: string
  ): Promise<AxiosResponse<DetailedMessageCampaignResponse>> {
    return this.client.get(`/message-campaigns/${campaignId}`);
  }

  duplicateCampaign(
    campaignId: string
  ): Promise<AxiosResponse<MessageCampaignResponse>> {
    return this.client.post(`/message-campaigns/${campaignId}/duplicate`);
  }
}
