import { type AxiosResponse } from 'axios';

import { EnumsUserSettingsGroupKey } from '@lp-lib/api-service-client/public';

import {
  type I18nSettings,
  type NotificationSettings,
  type NpsSettings,
  type PrivacySettings,
  type UserSettings,
  type VideoEffectsSettingsProfiles,
  type VirtualBackgroundSettings,
} from '../../types';
import { BaseAPIService } from './base.api';

export class UserSettingsAPI extends BaseAPIService {
  getMyPrivacySettings(): Promise<AxiosResponse<UserSettings>> {
    return this.client.get('/my/settings/privacy');
  }

  updateMyPrivacySettings(
    settings: Partial<PrivacySettings>
  ): Promise<AxiosResponse<UserSettings>> {
    return this.client.post('/my/settings/privacy', settings);
  }

  getMyNotificationSettings(): Promise<AxiosResponse<UserSettings>> {
    return this.client.get('/my/settings/notification');
  }

  updateMyNotificationSettings(
    settings: Partial<NotificationSettings>
  ): Promise<AxiosResponse<UserSettings>> {
    return this.client.post('/my/settings/notification', settings);
  }

  getMyNpsSettings(): Promise<AxiosResponse<UserSettings>> {
    return this.client.get('/my/settings/nps');
  }

  updateMyNpsSettings(
    settings: Partial<NpsSettings>
  ): Promise<AxiosResponse<UserSettings>> {
    return this.client.post('/my/settings/nps', settings);
  }

  getMyVbgSettings(): Promise<AxiosResponse<UserSettings>> {
    return this.client.get('/my/settings/vbg');
  }

  updateMyVbgSettings(
    settings: VirtualBackgroundSettings
  ): Promise<AxiosResponse<UserSettings>> {
    return this.client.post('/my/settings/vbg', settings);
  }

  getMyI18nSettings(): Promise<AxiosResponse<UserSettings>> {
    return this.client.get(
      `/my/settings/${EnumsUserSettingsGroupKey.UserSettingsGroupKeyI18N}`
    );
  }

  updateMyI18nSettings(
    settings: Partial<I18nSettings>
  ): Promise<AxiosResponse<UserSettings>> {
    return this.client.post(
      `/my/settings/${EnumsUserSettingsGroupKey.UserSettingsGroupKeyI18N}`,
      settings
    );
  }

  updateVesSettings(
    settings: VideoEffectsSettingsProfiles
  ): Promise<AxiosResponse<UserSettings>> {
    return this.client.post(
      `/my/settings/${EnumsUserSettingsGroupKey.UserSettingsGroupKeyVes}`,
      settings
    );
  }

  getVesSettings(): Promise<AxiosResponse<Partial<UserSettings>>> {
    return this.client.get(
      `/my/settings/${EnumsUserSettingsGroupKey.UserSettingsGroupKeyVes}`
    );
  }
}
