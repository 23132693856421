import { type AxiosResponse } from 'axios';

import { type JoyCapture } from '../../types/joyCapture';
import { BaseAPIService } from './base.api';

export type MultiJoyCaptureResponse = {
  joyCaptures: JoyCapture[];
};

export type QueryJoyCapturesRequest = {
  uids: string[];
  sessionId?: string;
};

export class JoyCaptureAPI extends BaseAPIService {
  query(
    query: QueryJoyCapturesRequest
  ): Promise<AxiosResponse<MultiJoyCaptureResponse>> {
    return this.client.get(`/joy-captures`, {
      params: query,
    });
  }
}
