import { type AxiosResponse } from 'axios';

import {
  type DtoCheckEmailAvailabilityRequest,
  type DtoExecuteTokenExchangeRequest,
  type DtoExecuteTokenExchangeResponse,
  type DtoGuestSignupRequest,
  type DtoOAuthRegisterRequest,
  type DtoPrepareTokenExchangeRequest,
  type DtoPrepareTokenExchangeResponse,
  type DtoRegisterRequest,
  type DtoSlackRegisterRequest,
  type DtoVerifyLoginRequest,
} from '@lp-lib/api-service-client/public';

import { type User } from '../../types/user';
import { BaseAPIService } from './base.api';

export interface RegisterRequest {
  firstName: string;
  lastName: string;
  email: string;
  organizationName: string;
}

export type OAuthProvider = 'google' | 'slack';

export interface LoginRequest {
  email: string;
  password: string;
}

export interface GuestLoginReques {
  username: string;
}

export interface OAuthLoginRequest {
  provider: OAuthProvider;
  code: string;
  redirectURI: string;
  loginFrom?: string;
}

export interface VerifyLoginRequest {
  identify: string;
  webDomain: string;
  payload?: string;
  retry?: boolean;
}

export type VerifyType = 'login' | 'invite';

export interface VerifyCallbackRequest {
  code: string;
}

export interface VerifyCallback extends UserAuthenticatedResponse {
  payload: string;
}

export interface UserAuthenticatedResponse {
  user: User;
  token: string;
}

export interface SignupRequest {
  email: string;
  username: string;
  password: string;
}

export interface GetFirebaseTokenResponse {
  token: string;
}

export enum AgoraTokenRole {
  Publisher = 1,
  Subscriber,
}

export interface GetAgoraRTCTokenRequest {
  sessionUid: string;
  channelName: string;
}

export interface TokenResponse {
  token: string;
}

export interface PresignedUrlResponse {
  presignedUrl: string;
}

export interface PresignedUrlRequest {
  key: string;
}

export interface GetChatTokenRequest {
  chatUid: string;
}

export interface GetChatTokenResponse {
  token: string;
}

export interface GenLoginURLByAdminResponse {
  url: string;
}

export type OnboardRequest = {
  orgId: string;
  firstName: string;
  lastName: string;
  email: string;
  timezone?: string | null;
};

export class AuthAPI extends BaseAPIService {
  checkEmailAvailability(
    req: DtoCheckEmailAvailabilityRequest
  ): Promise<AxiosResponse> {
    return this.client.post(`/check-email-availability`, req);
  }

  register(
    req: DtoRegisterRequest
  ): Promise<AxiosResponse<UserAuthenticatedResponse>> {
    return this.client.post('/register', req);
  }

  oauthRegister(
    req: DtoOAuthRegisterRequest
  ): Promise<AxiosResponse<UserAuthenticatedResponse>> {
    return this.client.post('/oauth-register', req);
  }

  slackRegister(
    req: DtoSlackRegisterRequest
  ): Promise<AxiosResponse<UserAuthenticatedResponse>> {
    return this.client.post('/slack-register', req);
  }

  login(req: LoginRequest): Promise<AxiosResponse<UserAuthenticatedResponse>> {
    return this.client.post<UserAuthenticatedResponse>('/login', req);
  }

  guestLogin(
    req: GuestLoginReques
  ): Promise<AxiosResponse<UserAuthenticatedResponse>> {
    return this.client.post<UserAuthenticatedResponse>('/guest-login', req);
  }

  oauthLogin(
    req: OAuthLoginRequest
  ): Promise<AxiosResponse<UserAuthenticatedResponse>> {
    return this.client.post<UserAuthenticatedResponse>('/oauth-login', req);
  }

  verifyLogin(req: DtoVerifyLoginRequest): Promise<AxiosResponse> {
    return this.client.post('/verify-login', req);
  }

  verifyCallback(
    req: VerifyCallbackRequest
  ): Promise<AxiosResponse<VerifyCallback>> {
    return this.client.post('/verify-callback', req);
  }

  deleteCode(code: string): Promise<AxiosResponse> {
    return this.client.delete(`/code/${code}`);
  }

  verifyCode(
    req: VerifyCallbackRequest
  ): Promise<AxiosResponse<Record<string, never>>> {
    return this.client.post('/verify-code', req);
  }

  signup(
    req: SignupRequest
  ): Promise<AxiosResponse<UserAuthenticatedResponse>> {
    return this.client.post<UserAuthenticatedResponse>('/signup', req);
  }

  verify(): Promise<AxiosResponse<UserAuthenticatedResponse>> {
    return this.client.post<UserAuthenticatedResponse>('/auth/verify');
  }

  genLoginURLByAdmin(
    uid: string
  ): Promise<AxiosResponse<GenLoginURLByAdminResponse>> {
    return this.client.post<GenLoginURLByAdminResponse>(
      `/auth/${uid}/gen-login-url`
    );
  }

  genSlackConnectURLByAdmin(
    uid: string
  ): Promise<AxiosResponse<GenLoginURLByAdminResponse>> {
    return this.client.post<GenLoginURLByAdminResponse>(
      `/auth/${uid}/gen-slack-connect-url`
    );
  }

  getFirebaseToken(): Promise<AxiosResponse<GetFirebaseTokenResponse>> {
    return this.client.post<GetFirebaseTokenResponse>(
      '/auth/get-firebase-token'
    );
  }

  getAgoraRTCToken(
    req: GetAgoraRTCTokenRequest,
    token?: string
  ): Promise<AxiosResponse<TokenResponse>> {
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
    return this.client.post<TokenResponse>('/auth/get-agora-rtc-token', req, {
      headers,
    });
  }

  getS3PresignedUrl(
    req: PresignedUrlRequest
  ): Promise<AxiosResponse<PresignedUrlResponse>> {
    return this.client.post<PresignedUrlResponse>(
      '/auth/get-presigned-url',
      req
    );
  }

  getChatToken(
    req: GetChatTokenRequest
  ): Promise<AxiosResponse<GetChatTokenResponse>> {
    return this.client.post<GetChatTokenResponse>('/auth/get-chat-token', req);
  }

  getTempToken(
    reCaptchaResponse: string
  ): Promise<AxiosResponse<TokenResponse>> {
    return this.client.post<TokenResponse>('/auth/get-temp-token', {
      reCaptchaResponse,
    });
  }

  onboard(
    req: OnboardRequest
  ): Promise<AxiosResponse<UserAuthenticatedResponse>> {
    return this.client.post<UserAuthenticatedResponse>('/onboard', req);
  }

  guestSignup(
    req: DtoGuestSignupRequest
  ): Promise<AxiosResponse<UserAuthenticatedResponse>> {
    return this.client.post('/guest-signup', req);
  }

  prepareTokenExchange(
    req: DtoPrepareTokenExchangeRequest
  ): Promise<AxiosResponse<DtoPrepareTokenExchangeResponse>> {
    return this.client.post('/auth/prepare-token-exchange', req);
  }

  executeTokenExchange(
    req: DtoExecuteTokenExchangeRequest
  ): Promise<AxiosResponse<DtoExecuteTokenExchangeResponse>> {
    return this.client.post('/auth/execute-token-exchange', req);
  }
}
