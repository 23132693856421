import { type AxiosResponse } from 'axios';

import { type DtoTranslationTextRequest } from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class TranslationAPI extends BaseAPIService {
  translate(
    req: DtoTranslationTextRequest
  ): Promise<AxiosResponse<DtoTranslationTextRequest>> {
    return this.client.post('/translation/translate', req);
  }
}
