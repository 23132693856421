import { type AxiosResponse } from 'axios';

import {
  type DtoCreateProductFeatureRequest,
  type DtoCreateProductRequest,
  type DtoNullableProductResponse,
  type DtoProductDefaultsResponse,
  type DtoProductFeatureResponse,
  type DtoProductFeaturesResponse,
  type DtoProductResponse,
  type DtoProductsResponse,
  type DtoUpdateMarketedProductsRequest,
  type DtoUpdateProductDefaultsRequest,
  type DtoUpdateProductFeatureRequest,
  type DtoUpdateProductRequest,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class ProductAPI extends BaseAPIService {
  getProducts(): Promise<AxiosResponse<DtoProductsResponse>> {
    return this.client.get('/products');
  }
  getProduct(id: string): Promise<AxiosResponse<DtoProductResponse>> {
    return this.client.get(`/products/${id}`);
  }
  createProduct(
    req: DtoCreateProductRequest
  ): Promise<AxiosResponse<DtoProductResponse>> {
    return this.client.post('/products', req);
  }
  updateProduct(
    id: string,
    req: DtoUpdateProductRequest
  ): Promise<AxiosResponse<DtoProductResponse>> {
    return this.client.put(`/products/${id}`, req);
  }
  getPublicProducts(): Promise<AxiosResponse<DtoProductsResponse>> {
    return this.client.get('/products/public');
  }
  updateMarketedProducts(
    req: DtoUpdateMarketedProductsRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.put(`/products/marketed`, req);
  }
  deleteProduct(id: string): Promise<AxiosResponse<void>> {
    return this.client.delete(`/products/${id}`);
  }
  duplicateProduct(id: string): Promise<AxiosResponse<void>> {
    return this.client.post(`/products/${id}/duplicate`);
  }
  publishProduct(id: string): Promise<AxiosResponse<void>> {
    return this.client.post(`/products/${id}/publish`);
  }

  createProductFeature(
    productId: string,
    req: DtoCreateProductFeatureRequest
  ): Promise<AxiosResponse<DtoProductFeatureResponse>> {
    return this.client.post(`/products/${productId}/features`, req);
  }
  getProductFeatures(
    productId: string
  ): Promise<AxiosResponse<DtoProductFeaturesResponse>> {
    return this.client.get(`/products/${productId}/features`);
  }
  updateProductFeature(
    productId: string,
    featureId: string,
    req: DtoUpdateProductFeatureRequest
  ): Promise<AxiosResponse<DtoProductFeatureResponse>> {
    return this.client.put(`/products/${productId}/features/${featureId}`, req);
  }
  deleteProductFeature(
    productId: string,
    featureId: string
  ): Promise<AxiosResponse<void>> {
    return this.client.delete(`/products/${productId}/features/${featureId}`);
  }
  getMostPopularProduct(): Promise<AxiosResponse<DtoNullableProductResponse>> {
    return this.client.get('/products/most-popular');
  }
  getLiveProduct(): Promise<AxiosResponse<DtoNullableProductResponse>> {
    return this.client.get('/products/live');
  }
  getProductDefaults(): Promise<AxiosResponse<DtoProductDefaultsResponse>> {
    return this.client.get('/products/defaults');
  }
  updateProductDefaults(
    req: DtoUpdateProductDefaultsRequest
  ): Promise<AxiosResponse<DtoProductDefaultsResponse>> {
    return this.client.put('/products/defaults', req);
  }
}
