import { type AxiosResponse } from 'axios';

import { type OnboardTask } from '../../types/onboard';
import { BaseAPIService } from './base.api';

type OnboardTaskResponse = {
  onboardTask: OnboardTask;
};

export class OnboardAPI extends BaseAPIService {
  getOnboardTask(
    idOrSlug: string
  ): Promise<AxiosResponse<OnboardTaskResponse>> {
    return this.client.get<OnboardTaskResponse>(`/onboard/tasks/${idOrSlug}`);
  }
}
