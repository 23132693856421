import { type AxiosResponse } from 'axios';

import {
  type DtoGenerateTopicBlocksRequest,
  type DtoGenerateTopicBlocksResponse,
  type DtoGenerateTopicOutlineRequest,
  type DtoGenerateTopicOutlineResponse,
  type DtoGenerateTrainingBlockRequest,
  type DtoGenerateTrainingBlockResponse,
  type DtoGenerateTrainingOutlineRequest,
  type DtoGenerateTrainingOutlineResponse,
  type DtoGetTrainingMusicLibraryResponse,
  type DtoGetTrainingPersonalitiesResponse,
  type DtoMakeTrainingCourseRequest,
  type DtoMakeTrainingCourseResponse,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class TrainingAPI extends BaseAPIService {
  getMusicLibrary(): Promise<
    AxiosResponse<DtoGetTrainingMusicLibraryResponse>
  > {
    return this.client.get('/training/music-library');
  }

  getPersonalities(): Promise<
    AxiosResponse<DtoGetTrainingPersonalitiesResponse>
  > {
    return this.client.get('/training/personalities');
  }

  generateTrainingOutline(
    req: DtoGenerateTrainingOutlineRequest
  ): Promise<AxiosResponse<DtoGenerateTrainingOutlineResponse>> {
    return this.client.post('/training/generate-training-outline', req);
  }

  generateTopicOutline(
    req: DtoGenerateTopicOutlineRequest
  ): Promise<AxiosResponse<DtoGenerateTopicOutlineResponse>> {
    return this.client.post('/training/generate-topic-outline', req);
  }

  generateTopicBlocks(
    req: DtoGenerateTopicBlocksRequest
  ): Promise<AxiosResponse<DtoGenerateTopicBlocksResponse>> {
    return this.client.post('/training/generate-topic-blocks', req);
  }

  generateTrainingBlock(
    req: DtoGenerateTrainingBlockRequest
  ): Promise<AxiosResponse<DtoGenerateTrainingBlockResponse>> {
    return this.client.post('/training/generate-training-block', req);
  }

  makeTrainingCourse(
    req: DtoMakeTrainingCourseRequest
  ): Promise<AxiosResponse<DtoMakeTrainingCourseResponse>> {
    return this.client.post('/training/make-training-course', req);
  }
}
