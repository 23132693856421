import { type AxiosResponse } from 'axios';

import {
  type DtoCountLiveEventsResponse,
  type DtoExperience,
  type DtoExperienceListResponse,
  type DtoGetPreUnsubscribeSummaryResponse,
  ModelsReportTimeRange,
} from '@lp-lib/api-service-client/public';

import { type JoyCapture } from '../../types/joyCapture';
import { BaseAPIService } from './base.api';
import { type Paginable, Paginator } from './pagination';

export const TIME_RANGES = Object.values(ModelsReportTimeRange);

export type TimeRangeKey = ModelsReportTimeRange;

export interface UserAnalyticsReport {
  id: string;
  uid: string;
  orgId: string;
  coworkersCount: number;
  playedCount: number;
  playedSeconds: number;
  slackEngagement: number;
  timeRange: string;
  fullName: string;
  lastActiveDate: string;
  firstActiveDate: string;
  isGuest: boolean;
  joyCapture?: JoyCapture;
}

export interface OrgDailySummary {
  id: string;
  orgId: string;
  connectionCount: number;
  sessionCount: number;
  previousSessionCount: number;
  sessionSeconds: number;
  slackEngagement: number;
  date: string;
}

export interface QueryUserReportsRequest {
  orgId: string;
  timeRange: string;
  name?: string;
}

export interface UserAnalyticsReportListResponse extends Paginable {
  reports: UserAnalyticsReport[] | null;
}

export interface OrgDailySummaryListResponse {
  summaries: OrgDailySummary[] | null;
  upcomingEventsCount: number;
}

export interface OrgSessionSecondsResponse {
  seconds: number;
}

export interface OrgParticipantPercentResponse {
  percent: number;
}

export class AnalyticsAPI extends BaseAPIService {
  queryUserReports(
    params: QueryUserReportsRequest
  ): Paginator<UserAnalyticsReportListResponse, UserAnalyticsReport> {
    return new Paginator<UserAnalyticsReportListResponse, UserAnalyticsReport>(
      this.client,
      '/analytics/user-reports',
      'reports',
      {
        size: 25,
        config: {
          params,
        },
      }
    );
  }

  queryOrgSummaries(params: {
    orgId: string;
    timeRange: TimeRangeKey;
  }): Promise<AxiosResponse<OrgDailySummaryListResponse>> {
    return this.client.get(`/analytics/org-summaries`, {
      params,
    });
  }

  getOrgSessionSeconds(params: {
    orgId: string;
    timeRange: TimeRangeKey;
  }): Promise<AxiosResponse<OrgSessionSecondsResponse>> {
    return this.client.get(`/analytics/org-session-seconds`, {
      params,
    });
  }

  getOrgParticipantPercent(
    params: QueryUserReportsRequest
  ): Promise<AxiosResponse<OrgParticipantPercentResponse>> {
    return this.client.get(`/analytics/org-participant-percent`, {
      params,
    });
  }

  queryMyExperiences(): Paginator<DtoExperienceListResponse, DtoExperience> {
    return new Paginator<DtoExperienceListResponse, DtoExperience>(
      this.client,
      `/my/experiences`,
      'experiences',
      {
        size: 36,
      }
    );
  }

  getPreUnsubscribeSummary(): Promise<
    AxiosResponse<DtoGetPreUnsubscribeSummaryResponse>
  > {
    return this.client.get(`/analytics/pre-unsubscribe`);
  }

  countLiveEvents(): Promise<AxiosResponse<DtoCountLiveEventsResponse>> {
    return this.client.get('/analytics/live-events-count');
  }
}
