import { type AxiosResponse } from 'axios';

import {
  type DtoExportSessionsRequest,
  type DtoExportSessionsResponse,
  type DtoGetSessionParticipantsResponse,
  type DtoGroupPhoto,
  type DtoRenderMemoriesSouvenirRequest,
  type DtoSessionVoiceOverAuditsResponse,
  type DtoTrackPlayedBlockRequest,
  type EnumsPlatform,
} from '@lp-lib/api-service-client/public';

import { type SentimentRecord } from '../../components/Sentiment/type';
import {
  type SessionMemories,
  type SessionMode,
  type SessionTrack,
} from '../../types';
import { BaseAPIService } from './base.api';
import { type DmgRenderResponse } from './dmg.api';
import { type Paginable, Paginator } from './pagination';

type ItemKey =
  | 'participants'
  | 'session'
  | 'teams'
  | 'scoreboard'
  | 'game-session-core'
  | 'game-session'
  | 'playback-info'
  | 'game-log'
  | 'anu-tracking';

export type SessionSnapshotItem = {
  key: ItemKey;
  remote: boolean;
  data?: unknown;
};

export type UpdateSessionSnapshotRequest = {
  items: SessionSnapshotItem[];
  isGameCompleted: boolean;
  silentRefresh?: boolean;
};

type StartSessionRequest = {
  orgId: string | null;
  mode: SessionMode;
  startedAt: number;
  gamePackId: string | null;
  pairingId: string | null;
  eventId: string | null;
  cohostUid: string | null;
  timeZone: string;
  platform?: EnumsPlatform;
};

type EndSessionRequest = {
  endedAt: number;
  isGameCompleted: boolean;
  silentRefresh?: boolean;
};

type UpdateSessionRequest = {
  orgId: string | null;
};

export type SessionTrackSearchParams = {
  mode?: SessionMode | null;
  onDemandControllerKind?: string | null;
  orgId?: string | null;
  venueId?: string | null;
  gamePackId?: string | null;
  gameId?: string | null;
  minPlayers?: number | null;
  minDurationSeconds?: number | null;
  eventOrganizerId?: string | null;
};

export interface SessionTrackListResponse extends Paginable {
  sessions: SessionTrack[];
}

export interface SessionTrackResponse {
  session: SessionTrack;
}

export interface SessionMemoriesResponse {
  session: SessionMemories;
}

type ReportSessionFailureRequest = {
  orgId: string;
  gamePackId: string;
  retryAttempts: number;
  timeZone: string;
  sessionId: null | undefined | string;
};

export class SessionAPI extends BaseAPIService {
  startSession(
    vid: string,
    sid: string,
    req: StartSessionRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.post<void>(`/venues/${vid}/sessions/${sid}/start`, req);
  }
  endSession(
    vid: string,
    sid: string,
    req: EndSessionRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.post<void>(`/venues/${vid}/sessions/${sid}/end`, req);
  }
  updateSessionSnapshot(
    vid: string,
    sid: string,
    req: UpdateSessionSnapshotRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.post<void>(
      `/venues/${vid}/sessions/${sid}/snapshot`,
      req
    );
  }
  uploadSessionSentiment(
    vid: string,
    sid: string,
    req: SentimentRecord
  ): Promise<AxiosResponse<void>> {
    return this.client.post<void>(
      `/venues/${vid}/sessions/${sid}/sentiments`,
      req
    );
  }
  search(
    params?: SessionTrackSearchParams
  ): Paginator<SessionTrackListResponse, SessionTrack> {
    return new Paginator<SessionTrackListResponse, SessionTrack>(
      this.client,
      '/sessions/search',
      'sessions',
      {
        size: 24,
        config: {
          params: params,
        },
      }
    );
  }
  updateSession(
    vid: string,
    sid: string,
    req: UpdateSessionRequest
  ): Promise<AxiosResponse<SessionTrackResponse>> {
    return this.client.put<SessionTrackResponse>(
      `/venues/${vid}/sessions/${sid}`,
      req
    );
  }
  getSession(sid: string): Promise<AxiosResponse<SessionTrackResponse>> {
    return this.client.get<SessionTrackResponse>(`/sessions/${sid}`);
  }
  getSessionMemories(
    sid: string,
    useParticipantTeamId?: boolean
  ): Promise<AxiosResponse<SessionMemoriesResponse>> {
    return this.client.get<SessionMemoriesResponse>(
      `/sessions/${sid}/memories`,
      { params: { useParticipantTeamId } }
    );
  }
  getSessionGroupPhoto(sid: string): Promise<AxiosResponse<DtoGroupPhoto>> {
    return this.client.get<DtoGroupPhoto>(`/sessions/${sid}/group-photo`);
  }
  generateSessionGroupPhoto(sid: string): Promise<AxiosResponse<void>> {
    return this.client.post<void>(`/sessions/${sid}/group-photo/generate`);
  }
  getSessionVoiceOverAudits(
    sid: string
  ): Promise<AxiosResponse<DtoSessionVoiceOverAuditsResponse>> {
    return this.client.get<DtoSessionVoiceOverAuditsResponse>(
      `/sessions/${sid}/voice-over-audits`
    );
  }
  reportFailure(
    vid: string,
    req: ReportSessionFailureRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.post<void>(
      `/venues/${vid}/sessions/report-failure`,
      req
    );
  }
  renderMemoriesTemplate(
    req: DtoRenderMemoriesSouvenirRequest
  ): Promise<AxiosResponse<DmgRenderResponse>> {
    return this.client.post(
      `/sessions/${req.sessionId}/memories/templates/render`,
      req
    );
  }
  trackPlayedBlocks(
    sid: string,
    req: DtoTrackPlayedBlockRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.post<void>(`/sessions/${sid}/track-played-blocks`, req);
  }
  getSessionParticipants(
    sid: string
  ): Promise<AxiosResponse<DtoGetSessionParticipantsResponse>> {
    return this.client.get(`/sessions/${sid}/participants`);
  }
  export(
    req: DtoExportSessionsRequest
  ): Promise<AxiosResponse<DtoExportSessionsResponse>> {
    return this.client.post('/sessions/export', req);
  }
}
