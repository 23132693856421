import { type AxiosResponse } from 'axios';

import { type MediaData } from '@lp-lib/game';

import {
  type DetailDrawing,
  type Drawing,
  type DrawingData,
} from '../../types/drawing';
import { BaseAPIService } from './base.api';
import { type Paginable, Paginator } from './pagination';

type DrawingResponse = {
  drawing: Drawing;
};

type CreateDrawingRequest = {
  sessionId: string;
  orgId: string | null;
  artworkMediaData: MediaData;
  data: Omit<DrawingData, 'backgroundMedia'>;
};

export interface DetailDrawingListResponse extends Paginable {
  drawings: DetailDrawing[];
}

export type DrawingSearchParams = {
  orgId?: string | null;
  uid?: string | null;
  sessionId?: string | null;
};

export class DrawingAPI extends BaseAPIService {
  create(req: CreateDrawingRequest): Promise<AxiosResponse<DrawingResponse>> {
    return this.client.post<DrawingResponse>('/drawings', req);
  }
  search(
    params?: DrawingSearchParams
  ): Paginator<DetailDrawingListResponse, DetailDrawing> {
    return new Paginator<DetailDrawingListResponse, DetailDrawing>(
      this.client,
      '/drawings/search',
      'drawings',
      {
        size: 24,
        config: {
          params: params,
        },
      }
    );
  }
}
