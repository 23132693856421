import { type AxiosResponse } from 'axios';

import { BaseAPIService } from './base.api';

export type DmgRenderRequest = {
  templateId: string;
  pairingId?: string;
  roundId?: string;
};

export type DmgRenderResponse = {
  url: string;
};

export type DmgTemplateResponse = {
  id: string;
  displayName: string;
  exampleImageUrl: string;
};

export type DmgTemplatesResponse = {
  templates: DmgTemplateResponse[];
};

export class DmgAPI extends BaseAPIService {
  renderTemplate(
    req: DmgRenderRequest
  ): Promise<AxiosResponse<DmgRenderResponse>> {
    return this.client.post(`/dmg/templates/${req.templateId}/render`, req);
  }

  getTemplates(): Promise<AxiosResponse<DmgTemplatesResponse>> {
    return this.client.get(`/dmg/templates`);
  }
}
