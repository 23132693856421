import { type AxiosResponse } from 'axios';

import { type MessageTemplate } from '../../types/messageTemplate';
import { BaseAPIService } from './base.api';
import { type Paginable, Paginator } from './pagination';

export type CreateMessageTemplateRequest = {
  title: string;
  body: string;
  mediaId: string | null;
  mediaTemplateId: string | null;
};

export type UpdateMessageTemplateRequest = CreateMessageTemplateRequest;

export interface MessageTemplatesResponse extends Paginable {
  messageTemplates: MessageTemplate[];
}

export interface MessageTemplateResponse {
  messageTemplate: MessageTemplate;
}

export class MessageTemplateAPI extends BaseAPIService {
  create(
    req: CreateMessageTemplateRequest
  ): Promise<AxiosResponse<MessageTemplateResponse>> {
    return this.client.post<MessageTemplateResponse>('/message-templates', req);
  }

  update(
    id: string,
    req: UpdateMessageTemplateRequest
  ): Promise<AxiosResponse<MessageTemplateResponse>> {
    return this.client.put<MessageTemplateResponse>(
      `/message-templates/${id}`,
      req
    );
  }

  get(id: string): Promise<AxiosResponse<MessageTemplateResponse>> {
    return this.client.get<MessageTemplateResponse>(`/message-templates/${id}`);
  }

  query(): Paginator<MessageTemplatesResponse, MessageTemplate> {
    return new Paginator<MessageTemplatesResponse, MessageTemplate>(
      this.client,
      '/message-templates',
      'messageTemplates',
      {
        size: 100,
      }
    );
  }

  delete(id: string): Promise<AxiosResponse> {
    return this.client.delete(`/message-templates/${id}`);
  }
}
