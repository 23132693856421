import { type AxiosResponse } from 'axios';

import {
  type DtoZoomCheckQuickLoginResponse,
  type DtoZoomLoginRequest,
  type DtoZoomLoginResponse,
  type DtoZoomLogoutRequest,
  type DtoZoomLogoutResponse,
  type DtoZoomSetupExperienceResponse,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class ZoomAPI extends BaseAPIService {
  login(
    req: DtoZoomLoginRequest
  ): Promise<AxiosResponse<DtoZoomLoginResponse>> {
    return this.client.post('/zoom/login', req);
  }
  logout(
    req: DtoZoomLogoutRequest
  ): Promise<AxiosResponse<DtoZoomLogoutResponse>> {
    return this.client.post('/zoom/logout', req);
  }
  checkQuickLoginAvailable(
    zoomUid: string
  ): Promise<AxiosResponse<DtoZoomCheckQuickLoginResponse>> {
    return this.client.post(`/zoom/check-quick-login-available`, { zoomUid });
  }
  setupExperience(
    packId: string
  ): Promise<AxiosResponse<DtoZoomSetupExperienceResponse>> {
    return this.client.post(`/zoom/experiences/${packId}/setup`);
  }
}
