import { type AxiosResponse } from 'axios';

import {
  type SettingsData,
  type SettingsKey,
  type SettingsName,
} from '../../types/settings';
import { BaseAPIService } from './base.api';

export interface SettingsDataResponse<Name extends SettingsName> {
  data: SettingsData<Name>;
}

export interface UpdateSettingsDataReq<
  Name extends SettingsName,
  Key extends SettingsKey<Name>
> {
  key: Key;
  value: SettingsData<Name>[Key];
}

export class SettingsAPI extends BaseAPIService {
  getData<T extends SettingsName>(
    name: T
  ): Promise<AxiosResponse<SettingsDataResponse<T>>> {
    return this.client.get(`/settings/${name}/data`);
  }
  updateValue<Name extends SettingsName, Key extends SettingsKey<Name>>(
    name: Name,
    req: UpdateSettingsDataReq<Name, Key>
  ): Promise<AxiosResponse> {
    return this.client.post(`/settings/${name}/data`, req);
  }
}
