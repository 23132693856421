import { type AxiosResponse } from 'axios';

import {
  type DtoPromotionHistoryListResponse,
  type DtoSendAnalyticsDigestRequest,
  type DtoSendGameUpdatesRequest,
  type DtoSendProgramPromotionRequest,
  type DtoToggleOrgGameUpdatesRequest,
} from '@lp-lib/api-service-client/public';

import { type GlobalPromotion, type Promotion } from '../../types/promotion';
import { BaseAPIService } from './base.api';
import { type Paginable } from './pagination';

export interface GlobalPromotionListResponse extends Paginable {
  globalPromotions: GlobalPromotion[];
}

export interface GlobalPromotionResponse extends Paginable {
  globalPromotion: GlobalPromotion;
}

export interface CreateGlobalPromotionRequest {
  title: string;
  startedAt: string;
  endedAt: string | null;
  messageCampaignId: string | null;
  orgIds: string[] | null;
}

export interface PromotionListResponse {
  promotions: Promotion[];
}

export type UpdateGlobalPromotionRequest =
  Partial<CreateGlobalPromotionRequest>;

export class PromotionAPI extends BaseAPIService {
  queryGlobalPromotions(): Promise<AxiosResponse<GlobalPromotionListResponse>> {
    return this.client.get(`/global-promotions`);
  }

  getGlobalPromotionById(
    globalPromotionId: string
  ): Promise<AxiosResponse<GlobalPromotionResponse>> {
    return this.client.get(`/global-promotions/${globalPromotionId}`);
  }

  createGlobalPromotion(
    req: CreateGlobalPromotionRequest
  ): Promise<AxiosResponse<GlobalPromotionResponse>> {
    return this.client.post('/global-promotions', req);
  }

  updateGlobalPromotion(
    globalPromotionId: string,
    req: UpdateGlobalPromotionRequest
  ): Promise<AxiosResponse<GlobalPromotionResponse>> {
    return this.client.put(`/global-promotions/${globalPromotionId}`, req);
  }

  deleteGlobalPromotion(
    globalPromotionId: string
  ): Promise<AxiosResponse<GlobalPromotionResponse>> {
    return this.client.delete(`/global-promotions/${globalPromotionId}`);
  }

  getPromotionsByGlobalId(
    globalPromotionId: string
  ): Promise<AxiosResponse<PromotionListResponse>> {
    return this.client.get(
      `/global-promotions/${globalPromotionId}/promotions`
    );
  }

  pausePromotion(promotionId: string): Promise<AxiosResponse> {
    return this.client.post(`/promotions/${promotionId}/pause`);
  }

  resumePromotion(promotionId: string): Promise<AxiosResponse> {
    return this.client.post(`/promotions/${promotionId}/resume`);
  }

  sendAnalyticsDigest(
    req: DtoSendAnalyticsDigestRequest
  ): Promise<AxiosResponse> {
    return this.client.post('/promotions/analytics-digest', req);
  }

  sendProgram(req: DtoSendProgramPromotionRequest): Promise<AxiosResponse> {
    return this.client.post('/promotions/program', req);
  }

  sendGameUpdates(req: DtoSendGameUpdatesRequest): Promise<AxiosResponse> {
    return this.client.post('/promotions/game-updates', req);
  }

  getHistories(): Promise<AxiosResponse<DtoPromotionHistoryListResponse>> {
    return this.client.get(`/promotions/histories`);
  }

  deleteHistory(id: string): Promise<AxiosResponse> {
    return this.client.delete(`/promotions/histories/${id}`);
  }

  toggleOrgGameUpdates(
    req: DtoToggleOrgGameUpdatesRequest
  ): Promise<AxiosResponse> {
    return this.client.post('/promotions/game-updates/toggle-by-org', req);
  }
}
