import { type AxiosResponse } from 'axios';

import {
  type DtoInvoiceListResponse,
  type DtoPromotionCodeListResponse,
  type DtoVerifyCheckoutSessionResponse,
  type QueryPromotionCodeParams,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class StripeAPI extends BaseAPIService {
  verifyCheckoutSession(
    sessionId: string
  ): Promise<AxiosResponse<DtoVerifyCheckoutSessionResponse>> {
    return this.client.get(`/stripe/checkout-sessions/${sessionId}/verify`);
  }
  queryPromotionCodes(
    params: QueryPromotionCodeParams
  ): Promise<AxiosResponse<DtoPromotionCodeListResponse>> {
    return this.client.get('/stripe/promotion-codes', { params });
  }
  queryInvoices(): Promise<AxiosResponse<DtoInvoiceListResponse>> {
    return this.client.get('/stripe/invoices');
  }
}
