import axios from 'axios';

import { type APIError } from '../../types';

export class APIServiceUtils {
  static CastAPIError(error: unknown): APIError | undefined {
    if (axios.isAxiosError(error)) {
      const data = error.response?.data;
      if (data && 'code' in data && 'key' in data && 'msg' in data) {
        return error.response?.data;
      }
    }
  }
}
