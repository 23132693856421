import { type AxiosResponse } from 'axios';

import { type DtoLLMGlobalSettingsResponse } from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class AIGeneralAPI extends BaseAPIService {
  getGlobalSettings(): Promise<AxiosResponse<DtoLLMGlobalSettingsResponse>> {
    return this.client.get(`/ai/global-settings`);
  }
}
