import { type AxiosResponse } from 'axios';

import {
  type DtoGradeSubmissionRequest,
  type DtoGradeSubmissionResponse,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export type ChatThreadSearchParams = {
  sessionId?: string | null;
};

export class AIGradeAPI extends BaseAPIService {
  gradeSubmission(
    req: DtoGradeSubmissionRequest
  ): Promise<AxiosResponse<DtoGradeSubmissionResponse>> {
    return this.client.post('/ai/grade', req);
  }
}
