import { type EnumsPlatform } from '@lp-lib/api-service-client/public';

import { type GamePack } from './game';
import { type JoyCapture } from './joyCapture';
import { type Organization } from './organization';

export enum SessionStatus {
  NotStarted = 'NotStarted',
  Live = 'Live',
  Ended = 'Ended',
  Aborted = 'Aborted',
}

export type SessionId = string;

export interface Session {
  id?: SessionId;
  status: SessionStatus;
  mode: SessionMode;
  firstStartedAt?: number | null;
  startedAt?: number | null;
  endedAt?: number | null;
  abortedAt?: number | null;
  abortRecoveryDisabled?: boolean;
  duration: number;
  cleanupAt?: number | null;
  statusChangedAt: number;
}

export enum SessionMode {
  Live = 'Live',
  OnDemand = 'OnDemand',
}

export type ScoreboardParticipant = {
  id: string;
  uid: string;
  name: string;
  joyCapture: JoyCapture | null;
};

export type ScoreboardEntry = {
  teamId: string;
  teamName: string;
  score: number | null;
  rank: number | null;
  teamMembers: string[];
};

export type SessionScoreboard = {
  scores: ScoreboardEntry[];
  participants: Record<string, ScoreboardParticipant>;
};

export type SessionMemories = {
  id: string;
  venueId: string;
  uid: string;
  status: SessionStatus;
  mode: SessionMode;
  startedAt: string;
  endedAt: string | null;
  pairingId: string | null;
  pairingRoundId: string | null;
  eventId: string | null;

  gamePack: GamePack | null;
  organization: Organization | null;
  scoreboard: SessionScoreboard | null;
};

export type SessionTrack = {
  id: string;
  venueId: string;
  uid: string;
  status: SessionStatus;
  mode: SessionMode;
  startedAt: string;
  endedAt: string | null;
  durationSeconds: number;
  maxPlayers: number;
  hostName: string;
  orgId: string | null;
  organizationName: string | null;
  gamePackId: string | null;
  gamePackName: string | null;
  gameId: string | null;
  gameName: string | null;
  maxScore: number;
  onDemandControllerKind: string | null;
  timeZone: string | null;
  platform: EnumsPlatform | null;
};

export class SessionUtils {
  static GetStreamId(s: SessionTrack): string {
    return `${s.venueId}/sessions/${s.id}`;
  }
  static ComposeInspectSessionUrl(s: SessionTrack): string {
    return `/admin/toolkit/sessions/inspector?streamId=${SessionUtils.GetStreamId(
      s
    )}`;
  }

  static ComposeInspectSessionUrlwithVenue(
    venueId: string,
    sessionId: string
  ): string {
    return `/admin/toolkit/sessions/inspector?streamId=${venueId}/sessions/${sessionId}`;
  }

  static ComposeMemoriesUrl(sessionId: string) {
    return `/sessions/${sessionId}/memories`;
  }
}
