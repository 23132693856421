import { type AxiosResponse } from 'axios';

import {
  type DtoAIChatMessageListResponse,
  type DtoAIChatThread,
  type DtoAIChatThreadListResponse,
  type DtoAIChatThreadResponse,
  type DtoTrackMessageRequest,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';
import { Paginator } from './pagination';

export type ChatThreadSearchParams = {
  sessionId?: string | null;
};

export class AIChatAPI extends BaseAPIService {
  trackMessages(req: DtoTrackMessageRequest): Promise<AxiosResponse<void>> {
    return this.client.post('/ai/chat/track-messages', req);
  }
  searchChatThreads(
    params?: ChatThreadSearchParams
  ): Paginator<DtoAIChatThreadListResponse, DtoAIChatThread> {
    return new Paginator<DtoAIChatThreadListResponse, DtoAIChatThread>(
      this.client,
      '/ai/chat/threads/search',
      'chatThreads',
      {
        size: 24,
        config: {
          params: params,
        },
      }
    );
  }

  getChatThreadById(
    threadId: string
  ): Promise<AxiosResponse<DtoAIChatThreadResponse>> {
    return this.client.get(`/ai/chat/threads/${threadId}`);
  }

  getMessagesByThreadId(
    threadId: string
  ): Promise<AxiosResponse<DtoAIChatMessageListResponse>> {
    return this.client.get(`/ai/chat/threads/${threadId}/messages`);
  }
}
