import { type AxiosResponse } from 'axios';

import { type OpenaiChatCompletionResponse } from '@lp-lib/api-service-client/public';

import { type NarrowedChatCompletionRequest } from '../../components/OpenAI';
import { BaseAPIService } from './base.api';

export class OpenAIAPI extends BaseAPIService {
  createChatCompletion(
    req: NarrowedChatCompletionRequest
  ): Promise<AxiosResponse<OpenaiChatCompletionResponse>> {
    return this.client.post('/openai/chat/completions', req);
  }
}
