import { type AxiosResponse } from 'axios';

import {
  type DtoListTrainingProfileResponse,
  type DtoSingleTrainingProfileResponse,
  type DtoUpsertTrainingProfileRequest,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class TrainingProfileAPI extends BaseAPIService {
  listProfiles(): Promise<AxiosResponse<DtoListTrainingProfileResponse>> {
    return this.client.get('/training/profiles');
  }
  createProfile(
    req: DtoUpsertTrainingProfileRequest
  ): Promise<AxiosResponse<DtoSingleTrainingProfileResponse>> {
    return this.client.post('/training/profiles', req);
  }
  getProfile(
    id: string
  ): Promise<AxiosResponse<DtoSingleTrainingProfileResponse>> {
    return this.client.get(`/training/profiles/${id}`);
  }
  updateProfile(
    id: string,
    req: DtoUpsertTrainingProfileRequest
  ): Promise<AxiosResponse<DtoSingleTrainingProfileResponse>> {
    return this.client.put(`/training/profiles/${id}`, req);
  }
  deleteProfile(id: string): Promise<AxiosResponse<void>> {
    return this.client.delete(`/training/profiles/${id}`);
  }
  lockProfile(
    id: string
  ): Promise<AxiosResponse<DtoSingleTrainingProfileResponse>> {
    return this.client.put(`/training/profiles/${id}/lock`);
  }
  unlockProfile(
    id: string
  ): Promise<AxiosResponse<DtoSingleTrainingProfileResponse>> {
    return this.client.put(`/training/profiles/${id}/unlock`);
  }
  duplicateProfile(
    id: string
  ): Promise<AxiosResponse<DtoSingleTrainingProfileResponse>> {
    return this.client.post(`/training/profiles/${id}/duplicate`);
  }
}
