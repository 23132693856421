import { type AxiosResponse } from 'axios';

import {
  type DtoAddMembersRequest,
  type DtoAddProgramLinkRequest,
  type DtoChannelListResponse,
  type DtoChannelOrgSummaryResponse,
  type DtoChannelProgramLinkResponse,
  type DtoChannelProgramLinksResponse,
  type DtoChannelResponse,
  type DtoCreateChannelRequest,
  type DtoCreateProgramRoundRequest,
  type DtoMemberListResponse,
  type DtoProgramRoundResponse,
  type DtoUpdateProgramLinkRequest,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export type ProgramLinkFilters = {
  orgId?: string;
  type?: string;
  status?: string;
};

export class ChannelAPI extends BaseAPIService {
  createChannel(
    req: DtoCreateChannelRequest
  ): Promise<AxiosResponse<DtoChannelResponse>> {
    return this.client.post('/channels', req);
  }
  queryChannels(orgId: string): Promise<AxiosResponse<DtoChannelListResponse>> {
    return this.client.get('/channels', {
      params: { orgId },
    });
  }
  addMembers(
    channelId: string,
    req: DtoAddMembersRequest
  ): Promise<AxiosResponse<DtoMemberListResponse>> {
    return this.client.post(`/channels/${channelId}/members`, req);
  }
  getChannel(channelId: string): Promise<AxiosResponse<DtoChannelResponse>> {
    return this.client.get<DtoChannelResponse>(`/channels/${channelId}`);
  }
  getChannelByProgramLinkId(
    linkId: string
  ): Promise<AxiosResponse<DtoChannelResponse>> {
    return this.client.get<DtoChannelResponse>(
      `/channels/program-links/${linkId}`
    );
  }
  getMembers(channelId: string): Promise<AxiosResponse<DtoMemberListResponse>> {
    return this.client.get(`/channels/${channelId}/members`);
  }
  snoozeMember(
    channelId: string,
    memberId: string
  ): Promise<AxiosResponse<void>> {
    return this.client.post(
      `/channels/${channelId}/members/${memberId}/snooze`
    );
  }
  unSnoozeMember(
    channelId: string,
    memberId: string
  ): Promise<AxiosResponse<void>> {
    return this.client.post(
      `/channels/${channelId}/members/${memberId}/unsnooze`
    );
  }
  removeMember(
    channelId: string,
    memberId: string
  ): Promise<AxiosResponse<void>> {
    return this.client.delete(`/channels/${channelId}/members/${memberId}`);
  }
  getOrgSummaries(): Promise<AxiosResponse<DtoChannelOrgSummaryResponse>> {
    return this.client.get(`/channels/org-summaries`);
  }
  deleteChannel(channelId: string): Promise<AxiosResponse<void>> {
    return this.client.delete(`/channels/${channelId}`);
  }
  activateProgramLink(
    channelId: string,
    linkId: string
  ): Promise<AxiosResponse<void>> {
    return this.client.post(
      `/channels/${channelId}/program-links/${linkId}/activate`
    );
  }
  deactivateProgramLink(
    channelId: string,
    linkId: string
  ): Promise<AxiosResponse<void>> {
    return this.client.post(
      `/channels/${channelId}/program-links/${linkId}/deactivate`
    );
  }
  addProgramLink(
    channelId: string,
    req: DtoAddProgramLinkRequest
  ): Promise<AxiosResponse<DtoChannelProgramLinkResponse>> {
    return this.client.post(`/channels/${channelId}/program-links`, req);
  }
  updateProgramLink(
    channelId: string,
    linkId: string,
    req: DtoUpdateProgramLinkRequest
  ): Promise<AxiosResponse<DtoChannelProgramLinkResponse>> {
    return this.client.put(
      `/channels/${channelId}/program-links/${linkId}`,
      req
    );
  }
  allProgramLinks(
    query?: ProgramLinkFilters
  ): Promise<AxiosResponse<DtoChannelProgramLinksResponse>> {
    return this.client.get(`/channels/program-links`, {
      params: query,
    });
  }
  createProgramRound(
    channelId: string,
    linkId: string,
    req: DtoCreateProgramRoundRequest
  ): Promise<AxiosResponse<DtoProgramRoundResponse>> {
    return this.client.post(
      `/channels/${channelId}/program-links/${linkId}/rounds`,
      req
    );
  }
  deleteProgramRound(
    channelId: string,
    linkId: string,
    roundId: string
  ): Promise<AxiosResponse<void>> {
    return this.client.delete(
      `/channels/${channelId}/program-links/${linkId}/rounds/${roundId}`
    );
  }
  updateProgramRound(
    channelId: string,
    linkId: string,
    roundId: string,
    req: DtoCreateProgramRoundRequest
  ): Promise<AxiosResponse<DtoProgramRoundResponse>> {
    return this.client.put(
      `/channels/${channelId}/program-links/${linkId}/rounds/${roundId}`,
      req
    );
  }
}
