import { type AxiosResponse } from 'axios';

import {
  type DtoCreateMessageLogicV2Request,
  type DtoCreateMessageTemplateSettingsRequest,
  type DtoMessageCampaignV2ListResponse,
  type DtoMessageLogicsV2Response,
  type DtoMessageLogicV2Response,
  type DtoMessageTemplateSettingsListResponse,
  type DtoMessageTemplateSettingsResponse,
  type DtoRenderSlackBlocksResponse,
  type DtoSendMarkdownEmailRequest,
  type DtoSendMarkdownSlackMessageRequest,
  type DtoSendMessageTemplateRequest,
  type DtoUpdateMessageLogicV2Request,
  type DtoUpdateMessageTemplateSettingsRequest,
  type DtoValidateMessageConditionRequest,
  type DtoValidateMessageConditionResponse,
  type EnumsMessageCampaignType,
  type RenderSlackBlocksParams,
} from '@lp-lib/api-service-client/public';

import { type Message, type MessageMedia } from '../../types';
import { BaseAPIService } from './base.api';

export interface MultiMessagesResponse {
  messages: Message[];
}

export interface SingleMessageResponse {
  message: Message;
}

export interface ShareMessageRequest {
  exGroupId: string;
  text: string;
  mediaList?: MessageMedia[];
}

export class MessageAPI extends BaseAPIService {
  queryMessages(params: {
    orgId?: string;
    parentId?: string;
    campaignId?: string;
  }): Promise<AxiosResponse<MultiMessagesResponse>> {
    return this.client.get(`/messages`, {
      params,
    });
  }

  revokeMessage(messageId: string): Promise<AxiosResponse<Message>> {
    return this.client.post(`/messages/${messageId}/revoke`);
  }

  sendMessage(messageId: string): Promise<AxiosResponse<Message>> {
    return this.client.post(`/messages/${messageId}/send`);
  }

  shareMessage(
    req: ShareMessageRequest
  ): Promise<AxiosResponse<SingleMessageResponse>> {
    return this.client.post(`/messages`, req);
  }

  getLogicsByCampaign(
    campaignType: EnumsMessageCampaignType,
    campaignId: string
  ): Promise<AxiosResponse<DtoMessageLogicsV2Response>> {
    return this.client.get(`/messages/logics`, {
      params: {
        campaignType,
        campaignId,
      },
    });
  }

  createLogic(
    req: DtoCreateMessageLogicV2Request
  ): Promise<AxiosResponse<DtoMessageLogicV2Response>> {
    return this.client.post(`/messages/logics`, req);
  }

  updateLogic(
    id: string,
    req: DtoUpdateMessageLogicV2Request
  ): Promise<AxiosResponse<DtoMessageLogicV2Response>> {
    return this.client.put(`/messages/logics/${id}`, req);
  }

  deleteLogic(id: string): Promise<AxiosResponse> {
    return this.client.delete(`/messages/logics/${id}`);
  }

  activateLogic(id: string): Promise<AxiosResponse> {
    return this.client.post(`/messages/logics/${id}/activate`);
  }

  deactivateLogic(id: string): Promise<AxiosResponse> {
    return this.client.post(`/messages/logics/${id}/deactivate`);
  }

  duplicateLogic(id: string): Promise<AxiosResponse> {
    return this.client.post(`/messages/logics/${id}/duplicate`);
  }

  sendTemplate(req: DtoSendMessageTemplateRequest): Promise<AxiosResponse> {
    return this.client.post(`/messages/templates/send`, req);
  }

  sendMarkdownEmail(req: DtoSendMarkdownEmailRequest): Promise<AxiosResponse> {
    return this.client.post(`/messages/templates/send-markdown-email`, req);
  }

  sendMarkdownSlackMessage(
    req: DtoSendMarkdownSlackMessageRequest
  ): Promise<AxiosResponse> {
    return this.client.post(
      `/messages/templates/send-markdown-slack-message`,
      req
    );
  }

  renderSlackBlocks(
    params: RenderSlackBlocksParams
  ): Promise<AxiosResponse<DtoRenderSlackBlocksResponse>> {
    return this.client.get(`/messages/templates/render-slack-blocks`, {
      params,
    });
  }

  validateCondition(
    req: DtoValidateMessageConditionRequest
  ): Promise<AxiosResponse<DtoValidateMessageConditionResponse>> {
    return this.client.post(`/messages/conditions/validate`, req);
  }

  createTemplateSettings(
    req: DtoCreateMessageTemplateSettingsRequest
  ): Promise<AxiosResponse<DtoMessageTemplateSettingsResponse>> {
    return this.client.post(`/messages/template-settings`, req);
  }

  updateTemplateSettings(
    id: string,
    req: DtoUpdateMessageTemplateSettingsRequest
  ): Promise<AxiosResponse<DtoMessageTemplateSettingsResponse>> {
    return this.client.put(`/messages/template-settings/${id}`, req);
  }

  deleteTemplateSettings(id: string): Promise<AxiosResponse> {
    return this.client.delete(`/messages/template-settings/${id}`);
  }

  getTemplateSettings(
    id: string
  ): Promise<AxiosResponse<DtoMessageTemplateSettingsResponse>> {
    return this.client.get(`/messages/template-settings/${id}`);
  }

  queryTemplateSettings(): Promise<
    AxiosResponse<DtoMessageTemplateSettingsListResponse>
  > {
    return this.client.get(`/messages/template-settings`);
  }

  queryMessageCampaigns(): Promise<
    AxiosResponse<DtoMessageCampaignV2ListResponse>
  > {
    return this.client.get(`/messages/campaigns`);
  }
}
