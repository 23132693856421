import { type AxiosResponse } from 'axios';

import {
  type DtoDerivedVenueSettings,
  type DtoDeriveVenueSettingsRequest,
  type DtoOverrideCoordinatorPolicyRequest,
} from '@lp-lib/api-service-client/public';
import { type TeamScoreboardData } from '@lp-lib/game';

import { type PlaybackInfoData } from '../../components/Game/Playback/PlaybackInfoProvider';
import { type GameLogData } from '../../components/GameLog/GameLogInfos';
import {
  type CloudController,
  type CloudInstanceBinding,
  type MemberId,
  type Participant,
  type Session,
  type Team,
  type TeamId,
  type TeamMember,
  type Venue,
  type VenueOwner,
} from '../../types';
import { BaseAPIService } from './base.api';

export interface SingleVenueResponse {
  venue: Venue;
}

export interface VenuesResponse {
  venues: Venue[];
}

export interface VenueOwnerResponse {
  owner: VenueOwner;
}

export interface UpdateVenueRequest {
  name?: string | null;
  backgroundMediaId?: string;
  guestEnabled?: boolean;
  displayName?: string;
  shareControl?: boolean;
}

export interface GetVenueSessionSnapshotResponse {
  data: {
    participants?: Record<string, Participant>;
    session?: Session;
    teams?: {
      'team-members': Record<TeamId, Record<MemberId, TeamMember>>;
      teams: Record<TeamId, Team>;
    };
    scoreboard?:
      | TeamScoreboardData[]
      | { teamScoreboard: TeamScoreboardData[] };
    'playback-info': PlaybackInfoData;
    // the backend will retreive an empty object `{}` if the key was empty.
    'game-log': Partial<GameLogData>;
  };
}

export interface CleanupVenueRequest {
  force: boolean;
}

export interface CreateCloudPlayerRequest {
  streamUrl: string;
  sessionUid: string;
  channelName: string;
  name: string;
  existingPlayerId?: string | null;
}

export interface CreateCloudPlayerResponse {
  id: string;
}

export type ApplyCloudHostingRequest = {
  url: string;
  group?: string;
};

export type ApplyCloudHostingResponse = {
  result: 'applied' | 'binding-exists' | 'unavailable';
};

export type ReleaseCloudHostingResponse = {
  result: 'released' | 'binding-already-released' | 'binding-not-found';
};

export type SyncCloudInstanceBindingRequest = {
  venueId: string;
} & Omit<CloudController, 'pingedAt'>;

export type GetCloudInstanceBindingResponse = {
  binding: CloudInstanceBinding;
};

export class VenueAPI extends BaseAPIService {
  createVenue(): Promise<AxiosResponse<SingleVenueResponse>> {
    return this.client.post<SingleVenueResponse>('/venues');
  }

  getVenueById(vid: string): Promise<AxiosResponse<SingleVenueResponse>> {
    return this.client.get<SingleVenueResponse>(`/venues/${vid}`);
  }

  headVenueById(vid: string): Promise<AxiosResponse> {
    return this.client.head(`/venues/${vid}`);
  }

  getMyVenue(): Promise<AxiosResponse<SingleVenueResponse>> {
    return this.client.get<SingleVenueResponse>('/my/venue');
  }

  getVenueOwnerById(vid: string): Promise<AxiosResponse<VenueOwnerResponse>> {
    return this.client.get<VenueOwnerResponse>(`/venues/${vid}/owner`);
  }

  updateVenue(
    req: UpdateVenueRequest
  ): Promise<AxiosResponse<SingleVenueResponse>> {
    return this.client.put<SingleVenueResponse>('/my/venue', req);
  }

  overrideCoordinatorPolicy(
    vid: string,
    req: DtoOverrideCoordinatorPolicyRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.post<void>(
      `/venues/${vid}/override-coordinator-policy`,
      req
    );
  }

  cleanupVenue(vid: string, force: boolean): Promise<AxiosResponse<void>> {
    return this.client.post<void>(`/venues/${vid}/cleanup`, { force });
  }
  deriveSettings(
    vid: string,
    req: DtoDeriveVenueSettingsRequest
  ): Promise<AxiosResponse<DtoDerivedVenueSettings>> {
    return this.client.post<DtoDerivedVenueSettings>(
      `/venues/${vid}/derive-settings`,
      req
    );
  }

  createCloudPlayer(
    req: CreateCloudPlayerRequest
  ): Promise<AxiosResponse<CreateCloudPlayerResponse>> {
    return this.client.post<CreateCloudPlayerResponse>(
      '/my/venue/cloud-players',
      req
    );
  }
  deleteCloudPlayer(id: string): Promise<AxiosResponse<void>> {
    return this.client.delete<void>(`/my/venue/cloud-players/${id}`);
  }
  getVenueSessionSnapshot(
    vid: string,
    sid: string
  ): Promise<AxiosResponse<GetVenueSessionSnapshotResponse>> {
    return this.client.get<GetVenueSessionSnapshotResponse>(
      `/venues/${vid}/sessions/${sid}/snapshot`
    );
  }
  applyCloudHosting(
    venueId: string,
    req: ApplyCloudHostingRequest
  ): Promise<AxiosResponse<ApplyCloudHostingResponse>> {
    return this.client.post<ApplyCloudHostingResponse>(
      `/venues/${venueId}/apply-cloud-hosting`,
      req
    );
  }
  releaseCloudHosting(
    venueId: string,
    reportToSlack?: boolean
  ): Promise<AxiosResponse<ReleaseCloudHostingResponse>> {
    return this.client.post<ReleaseCloudHostingResponse>(
      `/venues/${venueId}/release-cloud-hosting?${
        reportToSlack ? `report=true` : ``
      }`
    );
  }
  getCloudInstanceBinding(
    venueId: string
  ): Promise<AxiosResponse<GetCloudInstanceBindingResponse>> {
    return this.client.get(`/venues/${venueId}/cloud-instance-binding`);
  }
  syncCloudInstanceBinding(
    req: SyncCloudInstanceBindingRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.post(
      `/venues/${req.venueId}/sync-cloud-instance-binding`,
      req
    );
  }
}
