import { type AxiosResponse } from 'axios';

import {
  type DtoGetUserResponse,
  type DtoLookupNewUsersRequest,
  type DtoLookupNewUsersResponse,
  type DtoUpdateRoleRequest,
  type DtoUsersResponse,
  type QueryUsersParams,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class UserAPI extends BaseAPIService {
  updateUsername(username: string): Promise<AxiosResponse> {
    return this.client.put('/users/username', {
      username,
    });
  }
  updatePassword(password: string): Promise<AxiosResponse> {
    return this.client.put('/users/password', {
      password,
    });
  }
  updateRole(uid: string, req: DtoUpdateRoleRequest): Promise<AxiosResponse> {
    return this.client.put(`/users/${uid}/role`, req);
  }
  getUser(id: string): Promise<AxiosResponse<DtoGetUserResponse>> {
    return this.client.get(`/users/${id}`);
  }
  queryUsers(
    params: QueryUsersParams
  ): Promise<AxiosResponse<DtoUsersResponse>> {
    return this.client.get(`/users`, { params });
  }
  lookupNewUsers(
    req: DtoLookupNewUsersRequest
  ): Promise<AxiosResponse<DtoLookupNewUsersResponse>> {
    return this.client.post(`/users/lookup-new`, req);
  }
}
