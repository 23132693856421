import { type AxiosResponse } from 'axios';

import {
  type DtoCreateProgramRequest,
  type DtoCreateProgramRoundRequest,
  type DtoGetProgramsResponse,
  type DtoOpenProgramGroupGameResponse,
  type DtoProgramResponse,
  type DtoProgramRoundGroupsResponse,
  type DtoProgramRoundResponse,
  type DtoProgramRoundsResponse,
  type DtoQueryProgramsSummaryResponse,
  type DtoQueryRecognitionExGroupsResponse,
  type DtoQueryRecognitionSummaryResponse,
  type DtoStartProgramGroupGameRequest,
  type DtoUpdateActiveProgramsRequest,
  type DtoUpdateProgramRequest,
  type DtoUpdateProgramRoundRequest,
  type QueryProgramsSummaryParams,
  type QueryRecognitionSummaryParams,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class ProgramAPI extends BaseAPIService {
  getPrograms(): Promise<AxiosResponse<DtoGetProgramsResponse>> {
    return this.client.get('/programs');
  }
  getProgram(id: string): Promise<AxiosResponse<DtoProgramResponse>> {
    return this.client.get(`/programs/${id}`);
  }
  createProgram(
    req: DtoCreateProgramRequest
  ): Promise<AxiosResponse<DtoProgramResponse>> {
    return this.client.post('/programs', req);
  }
  updateProgram(
    id: string,
    req: DtoUpdateProgramRequest
  ): Promise<AxiosResponse<DtoProgramResponse>> {
    return this.client.put(`/programs/${id}`, req);
  }
  updateActivePrograms(
    req: DtoUpdateActiveProgramsRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.put(`/programs/active`, req);
  }
  getActivePrograms(): Promise<AxiosResponse<DtoGetProgramsResponse>> {
    return this.client.get('/programs/active');
  }
  createRound(
    programId: string,
    req: DtoCreateProgramRoundRequest
  ): Promise<AxiosResponse<DtoProgramRoundResponse>> {
    return this.client.post(`/programs/${programId}/rounds`, req);
  }
  duplicateRound(
    programId: string,
    roundId: string
  ): Promise<AxiosResponse<DtoProgramRoundResponse>> {
    return this.client.post(
      `/programs/${programId}/rounds/${roundId}/duplicate`
    );
  }
  queryRounds(
    parentId: string
  ): Promise<AxiosResponse<DtoProgramRoundsResponse>> {
    return this.client.get(`/program-rounds`, {
      params: { parentId },
    });
  }
  updateRound(
    programId: string,
    roundId: string,
    req: DtoUpdateProgramRoundRequest
  ): Promise<AxiosResponse<DtoProgramRoundResponse>> {
    return this.client.put(`/programs/${programId}/rounds/${roundId}`, req);
  }
  deleteRound(
    programId: string,
    roundId: string
  ): Promise<AxiosResponse<void>> {
    return this.client.delete(`/programs/${programId}/rounds/${roundId}`);
  }
  queryGroups(
    roundId: string
  ): Promise<AxiosResponse<DtoProgramRoundGroupsResponse>> {
    return this.client.get(`/programs/groups`, {
      params: { roundId },
    });
  }
  repairRound(
    programId: string,
    roundId: string
  ): Promise<AxiosResponse<void>> {
    return this.client.post(`/programs/${programId}/rounds/${roundId}/repair`);
  }
  openGroupGame(
    groupId: string
  ): Promise<AxiosResponse<DtoOpenProgramGroupGameResponse>> {
    return this.client.post(`/programs/groups/${groupId}/open-game`);
  }
  startGroupGame(
    groupId: string,
    req: DtoStartProgramGroupGameRequest
  ): Promise<AxiosResponse> {
    return this.client.post(`/programs/groups/${groupId}/start-game`, req);
  }
  queryRecognitionSummary(
    orgId: string,
    params: Omit<QueryRecognitionSummaryParams, 'id'>
  ): Promise<AxiosResponse<DtoQueryRecognitionSummaryResponse>> {
    return this.client.get(`/organizations/${orgId}/recognition-summary`, {
      params,
    });
  }
  queryRecognitionExGroups(
    orgId: string
  ): Promise<AxiosResponse<DtoQueryRecognitionExGroupsResponse>> {
    return this.client.get(`/organizations/${orgId}/recognition-groups`);
  }

  queryProgramsSummary(
    orgId: string,
    params: Omit<QueryProgramsSummaryParams, 'id'>
  ): Promise<AxiosResponse<DtoQueryProgramsSummaryResponse>> {
    return this.client.get(`/organizations/${orgId}/programs-summary`, {
      params,
    });
  }
}
