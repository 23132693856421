import { type AxiosResponse } from 'axios';

import { type DtoGetAutoScalingStatusResponse } from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

type CloudHostingBinding = {
  id: string;
  venueId: string;
  instanceId: string;
  state: string;
  createdAt: string;
  updatedAt: string;
};

type CloudHostingInstance = {
  instanceId: string;
  state: string;
  group: string | null;
  createdAt: string;
  updatedAt: string;
  syncedAt: string;
};

type CloudHostingEC2 = {
  InstanceId: string;
  State: {
    Code: number;
    Name: string;
  };
};

export type CloudHostingDetail = {
  Binding: CloudHostingBinding | null;
  Instance: CloudHostingInstance | null;
  EC2: CloudHostingEC2;
  InThePool: boolean | null;
};

export type CloudHostingDetailsResponse = {
  instanceDetails: CloudHostingDetail[];
};

export class CloudHostingAPI extends BaseAPIService {
  getCloudInstanceDetails(): Promise<
    AxiosResponse<CloudHostingDetailsResponse>
  > {
    return this.client.get('/cloud-hosting/cloud-instance-details');
  }

  startInstance(instanceId: string): Promise<AxiosResponse> {
    return this.client.post(`/cloud-hosting/instances/${instanceId}/start`);
  }

  enableInstance(instanceId: string): Promise<AxiosResponse> {
    return this.client.post(`/cloud-hosting/instances/${instanceId}/enable`);
  }

  disableInstance(instanceId: string): Promise<AxiosResponse> {
    return this.client.post(`/cloud-hosting/instances/${instanceId}/disable`);
  }

  getAutoScalingStatus(): Promise<
    AxiosResponse<DtoGetAutoScalingStatusResponse>
  > {
    return this.client.get(`/cloud-hosting/auto-scaling/status`);
  }

  enableAutoScaling(): Promise<AxiosResponse> {
    return this.client.post(`/cloud-hosting/auto-scaling/enable`);
  }

  disableAutoScaling(): Promise<AxiosResponse> {
    return this.client.post(`/cloud-hosting/auto-scaling/disable`);
  }
}
