import { type AxiosResponse } from 'axios';

import {
  type DtoUpdateTagRequest,
  type EnumsPageName,
} from '@lp-lib/api-service-client/public';

import { type Tag } from '../../types/tag';
import { BaseAPIService } from './base.api';
import { type Paginable, Paginator } from './pagination';

export interface TagListResponse extends Paginable {
  tags: Tag[];
}

export interface SingleTagResponse {
  tag: Tag;
}

export class TagAPI extends BaseAPIService {
  getFeaturedTags(
    context: EnumsPageName
  ): Promise<AxiosResponse<TagListResponse>> {
    return this.client.get<TagListResponse>('/tags', {
      params: { type: 'featured', key: context },
    });
  }

  getTagBySlug(slug: string): Promise<AxiosResponse<SingleTagResponse>> {
    return this.client.get<SingleTagResponse>(`/tags/${slug}`);
  }

  getTag(idOrSlug: number | string): Promise<AxiosResponse<SingleTagResponse>> {
    return this.client.get<SingleTagResponse>(`/tags/${idOrSlug}`);
  }

  search(q?: string | null): Paginator<TagListResponse, Tag> {
    return new Paginator<TagListResponse, Tag>(
      this.client,
      '/tags/search',
      'tags',
      {
        size: 100,
        config: {
          params: { q },
        },
      }
    );
  }

  updateFeaturedTags(
    context: EnumsPageName,
    tagIds: number[]
  ): Promise<AxiosResponse> {
    return this.client.put('/tags/featured', {
      tagIds,
      name: context,
    });
  }

  updateTag(
    id: number,
    req: DtoUpdateTagRequest
  ): Promise<AxiosResponse<SingleTagResponse>> {
    return this.client.put<SingleTagResponse>(`/tags/${id}`, req);
  }
}
