import { type AxiosResponse } from 'axios';

import { type MessageLogic } from '../../types';
import { BaseAPIService } from './base.api';
import { type Paginable, Paginator } from './pagination';

export interface MessageLogicsResponse extends Paginable {
  logics: MessageLogic[];
}

export interface UpdateMessageLogicRequest {
  id: string;
  templateId: string;
}

export interface QueryMessageLogicRequest {
  parentId?: string;
  isDefault?: boolean;
  campaignId?: string;
  size?: 100;
}

export interface DuplicateMessageCampaignResponse {
  parentId: string;
}

export class MessageLogicAPI extends BaseAPIService {
  query(
    params: QueryMessageLogicRequest
  ): Paginator<MessageLogicsResponse, MessageLogic> {
    return new Paginator(this.client, `/message-logics`, 'logics', {
      config: {
        params,
      },
    });
  }

  update(req: UpdateMessageLogicRequest): Promise<AxiosResponse> {
    return this.client.put(`/message-logics/${req.id}`, req);
  }
}
