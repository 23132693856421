import { type AxiosResponse } from 'axios';

import {
  type DtoHeyGenTemplateVariablesResponse,
  type DtoListHeyGenAvatarsResponse,
  type DtoListHeyGenTemplatesResponse,
  type DtoTTSGeneratorList,
  type DtoTTSGeneratorSettings,
  type DtoTTSRenderRequest,
  type DtoTTSRenderResponse,
  type DtoTTSRenderRuntimeSampleRequest,
  type DtoTTSResolveCodeFencesRequest,
  type DtoTTSResolveCodeFencesResponse,
  type EnumsTTSGeneratorId,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class TextToSpeechAPI extends BaseAPIService {
  getGenerators(): Promise<AxiosResponse<DtoTTSGeneratorList>> {
    return this.client.get('/tts/generators');
  }
  getGeneratorSettings(
    generatorId: EnumsTTSGeneratorId
  ): Promise<AxiosResponse<DtoTTSGeneratorSettings>> {
    return this.client.get(`/tts/generators/${generatorId}`);
  }
  render(req: DtoTTSRenderRequest): Promise<AxiosResponse<Blob>> {
    return this.client.post(`/tts/render`, req, {
      responseType: 'blob',
    });
  }
  resolveCodeFences(
    req: DtoTTSResolveCodeFencesRequest
  ): Promise<AxiosResponse<DtoTTSResolveCodeFencesResponse>> {
    return this.client.post(`/tts/resolve-code-fences`, req);
  }
  renderUpload(
    req: DtoTTSRenderRequest
  ): Promise<AxiosResponse<DtoTTSRenderResponse>> {
    return this.client.post(`/tts/render/upload`, req);
  }
  renderRuntimeSample(
    req: DtoTTSRenderRuntimeSampleRequest
  ): Promise<AxiosResponse<Blob>> {
    return this.client.post(`/tts/render-runtime-sample`, req, {
      responseType: 'blob',
    });
  }
  listHeyGenAvatars(): Promise<AxiosResponse<DtoListHeyGenAvatarsResponse>> {
    return this.client.get(`/avatars/heygen`);
  }
  listHeyGenTemplates(): Promise<
    AxiosResponse<DtoListHeyGenTemplatesResponse>
  > {
    return this.client.get(`/avatars/heygen/templates`);
  }
  getHeyGenTemplateVariables(
    templateId: string
  ): Promise<AxiosResponse<DtoHeyGenTemplateVariablesResponse>> {
    return this.client.get(`/avatars/heygen/templates/${templateId}`);
  }
}
