import { type AxiosResponse } from 'axios';

import {
  type DtoListOnboardingTasksResponse,
  type DtoOnboardingTaskLogsResponse,
  type DtoOperateTaskRequest,
  type EnumsOnboardingTaskName,
  type QueryTaskLogsParams,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class OnboardingAPI extends BaseAPIService {
  listTasks(
    orgId: string
  ): Promise<AxiosResponse<DtoListOnboardingTasksResponse>> {
    return this.client.get(`/onboarding/tasks`, {
      params: {
        orgId,
      },
    });
  }
  operateTask(
    name: EnumsOnboardingTaskName,
    req: DtoOperateTaskRequest
  ): Promise<AxiosResponse> {
    return this.client.post(`/onboarding/tasks/${name}/operate`, req);
  }
  queryTaskLogs(
    params: QueryTaskLogsParams
  ): Promise<AxiosResponse<DtoOnboardingTaskLogsResponse>> {
    return this.client.get(`/onboarding/task-logs`, {
      params,
    });
  }
}
