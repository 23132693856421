import { type AxiosResponse } from 'axios';

import {
  type DtoGetFeaturedResponse,
  type DtoGetPageResponse,
  type EnumsPageName,
  type FeaturedListParams,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class PagesAPI extends BaseAPIService {
  get(name: EnumsPageName): Promise<AxiosResponse<DtoGetPageResponse>> {
    return this.client.get(`/pages/${name}`);
  }
  getFeatured(
    params: FeaturedListParams
  ): Promise<AxiosResponse<DtoGetFeaturedResponse>> {
    return this.client.get('/pages/featured', {
      params,
    });
  }
}
