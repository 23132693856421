import { type AxiosResponse } from 'axios';

import {
  type DtoBrand,
  type DtoBrandsCountResponse,
  type DtoCreateBrandRequest,
  type DtoMultiBrandResponse,
  type DtoSingleBrandResponse,
  type DtoUpdateBrandRequest,
  EnumsBrandQueryType,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';
import { Paginator } from './pagination';

export class BrandAPI extends BaseAPIService {
  create(
    req: DtoCreateBrandRequest
  ): Promise<AxiosResponse<DtoSingleBrandResponse>> {
    return this.client.post('/brands', req);
  }
  duplicate(id: string): Promise<AxiosResponse<DtoSingleBrandResponse>> {
    return this.client.post(`/brands/${id}/duplicate`);
  }
  update(id: string, req: DtoUpdateBrandRequest): Promise<AxiosResponse> {
    return this.client.put(`/brands/${id}`, req);
  }
  delete(id: string): Promise<AxiosResponse> {
    return this.client.delete(`/brands/${id}`);
  }
  get(id: string): Promise<AxiosResponse<DtoSingleBrandResponse>> {
    return this.client.get(`/brands/${id}`);
  }
  getPublic(id: string): Promise<AxiosResponse<DtoSingleBrandResponse>> {
    return this.client.get(`/brands/${id}/public`);
  }
  getByTagId(tagId: number, pageSize = 96) {
    return new Paginator<DtoMultiBrandResponse, DtoBrand>(
      this.client,
      '/brands',
      'brands',
      {
        size: pageSize,
        config: {
          params: { type: EnumsBrandQueryType.BrandQueryTypeTag, tagId },
        },
      }
    );
  }
  getUntagged(pageSize = 96) {
    return new Paginator<DtoMultiBrandResponse, DtoBrand>(
      this.client,
      '/brands',
      'brands',
      {
        size: pageSize,
        config: {
          params: { type: EnumsBrandQueryType.BrandQueryTypeUntagged },
        },
      }
    );
  }
  search(q: string, pageSize = 96) {
    return new Paginator<DtoMultiBrandResponse, DtoBrand>(
      this.client,
      '/brands/search',
      'brands',
      {
        size: pageSize,
        config: {
          params: { q },
        },
      }
    );
  }
  count(): Promise<AxiosResponse<DtoBrandsCountResponse>> {
    return this.client.get('/brands/count');
  }
}
