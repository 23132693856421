import { APIService } from './APIService';
export * from './auth.api';
export * from './block.api';
export * from './game.api';
export * from './gamePack.api';
export * from './misc.api';
export * from './organization.api';
export * from './pagination';
export * from './pairing.api';
export * from './session.api';
export * from './tag.api';
export * from './user.api';
export * from './venue.api';

export const apiService = new APIService();
