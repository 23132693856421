import { type AxiosResponse } from 'axios';

import {
  type Birthday,
  type BundledCelebration,
  type Celebration,
  type CelebrationEvent,
  type CelebrationMessageSettings,
  type CelebrationParticipant,
  type WorkStartDate,
} from '../../types/celebration';
import { BaseAPIService } from './base.api';

export interface CelebrationResponse {
  celebration: Celebration;
}

export interface BundledCelebrationsResponse {
  celebrations: BundledCelebration[];
}

export interface CreateCelebrationRequest {
  channelId: string;
  timezone: string;
}

export interface UpdateCelebrationMessageSettingsRequest {
  messageSettings: CelebrationMessageSettings;
}

export interface CelebrationParticipantsResponse {
  participants: CelebrationParticipant[];
}

export interface UpdateCelebrationParticipantRequest {
  celebrateBirthday?: boolean;
}

export interface ImportCelebrationDateItem {
  email: string;
  birthday: Birthday | null;
  workStartDate: WorkStartDate | null;
}

export interface ImportCelebrationDatesRequest {
  items: ImportCelebrationDateItem[];
}

export interface ImportCelebrationDateResult {
  email: string;
  result: 'success' | 'invalid' | 'not-in-workspace' | 'not-in-channel';
}

export interface ImportCelebrationDatesResponse {
  birthdayCount: number;
  workStartDateCount: number;
  results: ImportCelebrationDateResult[];
}

export interface CelebrationEventsResponse {
  events: CelebrationEvent[];
}

export class CelebrationAPI extends BaseAPIService {
  createCelebration(
    req: CreateCelebrationRequest
  ): Promise<AxiosResponse<CelebrationResponse>> {
    return this.client.post(`/celebrations`, req);
  }

  allCelebrations(): Promise<AxiosResponse<BundledCelebrationsResponse>> {
    return this.client.get(`/celebrations`);
  }

  getCelebration(id: string): Promise<AxiosResponse<CelebrationResponse>> {
    return this.client.get(`/celebrations/${id}`);
  }

  updateMessageSettings(
    id: string,
    req: UpdateCelebrationMessageSettingsRequest
  ): Promise<AxiosResponse> {
    return this.client.put(`/celebrations/${id}/message-settings`, req);
  }

  getParticipants(
    id: string
  ): Promise<AxiosResponse<CelebrationParticipantsResponse>> {
    return this.client.get(`/celebrations/${id}/participants`);
  }

  updateParticipantSettings(
    id: string,
    uid: string,
    req: UpdateCelebrationParticipantRequest
  ): Promise<AxiosResponse> {
    return this.client.put(
      `/celebrations/${id}/participants/${uid}/settings`,
      req
    );
  }

  updateParticipantBirthday(
    id: string,
    uid: string,
    birthday: Birthday | null
  ): Promise<AxiosResponse> {
    return this.client.put(`/celebrations/${id}/participants/${uid}/birthday`, {
      birthday,
    });
  }

  updateParticipantWorkStartDate(
    id: string,
    uid: string,
    workStartDate: WorkStartDate | null
  ): Promise<AxiosResponse> {
    return this.client.put(
      `/celebrations/${id}/participants/${uid}/work-start-date`,
      {
        workStartDate,
      }
    );
  }

  importDates(
    id: string,
    req: ImportCelebrationDatesRequest
  ): Promise<AxiosResponse<ImportCelebrationDatesResponse>> {
    return this.client.post(
      `/celebrations/${id}/participants/import-dates`,
      req
    );
  }

  getUpcomingEvents(
    id: string,
    days: number
  ): Promise<AxiosResponse<CelebrationEventsResponse>> {
    return this.client.get(`/celebrations/${id}/events/upcoming`, {
      params: {
        days,
      },
    });
  }
}
