import { type AxiosResponse } from 'axios';

import {
  type DtoSlackAddUsersToChannelRequest,
  type DtoSlackChannelListResponse,
  type DtoSlackCreateChannelRequest,
  type DtoSlackCreateChannelResponse,
  type DtoSlackUserListResponse,
  type QuerySlackUsersParams,
  type SearchSlackChannelsParams,
} from '@lp-lib/api-service-client/public';

import { type OrgConnection } from '../../types';
import {
  type SlackChannel,
  type SlackInstallation,
  type SlackSocketModeConnection,
  type SlackUser,
} from '../../types/slack';
import { BaseAPIService } from './base.api';

type SlackGenerateInstallUrlResponse = {
  url: string;
};

type SlackInstallCallbackRequest = {
  code: string;
  state: string;
};

type SlackInstallCallbackResponse = {
  connection: OrgConnection;
};

export type SlackChannelSearchParams = {
  orgId: string;
  keyword: string;
  types: string;
  size: number;
  withNewUsers?: boolean;
};

export type SlackChannelQueryParams = {
  type: 'byIds' | 'all' | 'byUserId';
  orgId: string;
  channelIds?: string;
};

type SlackChannelResponse = {
  channel: SlackChannel;
};

type SlackChannelListResponse = {
  channels: SlackChannel[];
  newUsersByChannelId: Record<string, SlackUser[] | null>;
};

type SlackSocketModeConnectionListResponse = {
  connections: SlackSocketModeConnection[];
};

type SlackInstallationResponse = {
  installation: SlackInstallation;
};

export class SlackAPI extends BaseAPIService {
  generateInstallUrl(
    parameters?: Record<string, string>
  ): Promise<AxiosResponse<SlackGenerateInstallUrlResponse>> {
    return this.client.post<SlackGenerateInstallUrlResponse>(
      '/slack/generate-install-url',
      { parameters }
    );
  }
  installCallback(
    req: SlackInstallCallbackRequest
  ): Promise<AxiosResponse<SlackInstallCallbackResponse>> {
    return this.client.get<SlackInstallCallbackResponse>(
      '/slack/install-callback',
      {
        params: {
          ...req,
        },
      }
    );
  }
  getInstallation(): Promise<AxiosResponse<SlackInstallationResponse>> {
    return this.client.get('/slack/installation');
  }
  searchChannels(
    params: SearchSlackChannelsParams
  ): Promise<AxiosResponse<DtoSlackChannelListResponse>> {
    return this.client.get('/slack/channels/search', {
      params,
    });
  }
  queryChannels(
    params: SlackChannelQueryParams
  ): Promise<AxiosResponse<SlackChannelListResponse>> {
    return this.client.get('/slack/channels', {
      params,
    });
  }
  createChannel(
    req: DtoSlackCreateChannelRequest
  ): Promise<AxiosResponse<SlackChannelResponse>> {
    return this.client.post<SlackChannelResponse>('/slack/channels', req);
  }
  findOrCreateChannel(
    req: DtoSlackCreateChannelRequest
  ): Promise<AxiosResponse<DtoSlackCreateChannelResponse>> {
    return this.client.post('/slack/find-or-create-channel', req);
  }
  queryUsers(
    params: QuerySlackUsersParams
  ): Promise<AxiosResponse<DtoSlackUserListResponse>> {
    return this.client.get('/slack/users', {
      params,
    });
  }
  addUsersToChannel(
    req: DtoSlackAddUsersToChannelRequest
  ): Promise<AxiosResponse> {
    return this.client.post('/slack/channels/add-users', req);
  }
  getSocketModeConnections(): Promise<
    AxiosResponse<SlackSocketModeConnectionListResponse>
  > {
    return this.client.get<SlackSocketModeConnectionListResponse>(
      '/slack/socket-mode-connections'
    );
  }
  updateSocketModeConnections(): Promise<AxiosResponse> {
    return this.client.post('/slack/socket-mode-connections');
  }
}
