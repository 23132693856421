import { type AxiosResponse } from 'axios';

import {
  type DtoCreatePromptTemplateRequest,
  type DtoPromptTemplateListResponse,
  type DtoPromptTemplateResponse,
  type DtoUpdatePromptTemplateRequest,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class PromptTemplateAPI extends BaseAPIService {
  getTemplates(): Promise<AxiosResponse<DtoPromptTemplateListResponse>> {
    return this.client.get('/prompt-templates');
  }
  getTemplateById(
    id: string
  ): Promise<AxiosResponse<DtoPromptTemplateResponse>> {
    return this.client.get(`/prompt-templates/${id}`);
  }
  createTemplate(
    req: DtoCreatePromptTemplateRequest
  ): Promise<AxiosResponse<DtoPromptTemplateResponse>> {
    return this.client.post('prompt-templates', req);
  }
  updateTemplate(
    id: string,
    req: DtoUpdatePromptTemplateRequest
  ): Promise<AxiosResponse<DtoPromptTemplateResponse>> {
    return this.client.put(`prompt-templates/${id}`, req);
  }
  deleteTemplate(id: string): Promise<AxiosResponse<void>> {
    return this.client.delete(`prompt-templates/${id}`);
  }
  cloneTemplate(id: string): Promise<AxiosResponse<DtoPromptTemplateResponse>> {
    return this.client.post(`prompt-templates/${id}/clone`);
  }
}
