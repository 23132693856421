import { type AxiosResponse } from 'axios';

import {
  type DtoCreateInquiryRequest,
  type DtoCreateInterviewRequest,
  type DtoCreateQuestionReplyRequest,
  type DtoGenerateLineOfInquiryRequest,
  type DtoLineOfInquiryResponse,
  type DtoMultiSummarizedInterviewResponse,
  type DtoSingleInquiryResponse,
  type DtoSingleInterviewResponse,
  type DtoUpdateInterviewRequest,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class CollectiveAPI extends BaseAPIService {
  generateLineOfInquiry(
    req: DtoGenerateLineOfInquiryRequest
  ): Promise<AxiosResponse<DtoLineOfInquiryResponse>> {
    return this.client.post('/inquiries/generate', req);
  }

  createInquiry(
    req: DtoCreateInquiryRequest
  ): Promise<AxiosResponse<DtoSingleInquiryResponse>> {
    return this.client.post('/inquiries', req);
  }

  getInquiry(id: string): Promise<AxiosResponse<DtoSingleInquiryResponse>> {
    return this.client.get(`/inquiries/${id}`);
  }

  summarizeInquiry(
    id: string
  ): Promise<AxiosResponse<DtoSingleInquiryResponse>> {
    return this.client.post(`/inquiries/${id}/summarize`);
  }

  createInterview(
    req: DtoCreateInterviewRequest
  ): Promise<AxiosResponse<DtoSingleInterviewResponse>> {
    return this.client.post('/interviews', req);
  }

  getInterview(id: string): Promise<AxiosResponse<DtoSingleInterviewResponse>> {
    return this.client.get(`/interviews/${id}`);
  }

  updateInterview(
    req: DtoUpdateInterviewRequest
  ): Promise<AxiosResponse<DtoSingleInterviewResponse>> {
    return this.client.put(`/interviews/${req.interviewId}`, req);
  }

  submitInterview(
    id: string
  ): Promise<AxiosResponse<DtoSingleInterviewResponse>> {
    return this.client.post(`/interviews/${id}/submit`);
  }

  renderInterviewWelcome(id: string): Promise<AxiosResponse<Blob>> {
    return this.client.post(`/interviews/${id}/render-welcome`, undefined, {
      responseType: 'blob',
    });
  }

  startInterview(
    id: string
  ): Promise<AxiosResponse<DtoSingleInterviewResponse>> {
    return this.client.post(`/interviews/${id}/start`);
  }

  createQuestionReply(
    req: DtoCreateQuestionReplyRequest
  ): Promise<AxiosResponse<DtoSingleInterviewResponse>> {
    return this.client.post(`/interviews/${req.interviewId}/reply`, req);
  }

  summarizeInterview(
    id: string
  ): Promise<AxiosResponse<DtoSingleInterviewResponse>> {
    return this.client.post(`/interviews/${id}/summarize`);
  }

  resummarizeInterview(
    id: string
  ): Promise<AxiosResponse<DtoSingleInterviewResponse>> {
    return this.client.post(`/interviews/${id}/resummarize`);
  }

  getInquiryResponses(
    id: string
  ): Promise<AxiosResponse<DtoMultiSummarizedInterviewResponse>> {
    return this.client.get(`/inquiries/${id}/responses`);
  }
}
