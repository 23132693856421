import { type AxiosResponse } from 'axios';

import {
  type DtoGetTestResultResponse,
  type DtoReportTestResultRequest,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

type PingResponse = {
  pong: true;
  timestampMs: number;
};

export class MiscAPI extends BaseAPIService {
  reportTestResult(
    req: DtoReportTestResultRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.post('/network-test/report', req);
  }
  getLastTestResult(): Promise<AxiosResponse<DtoGetTestResultResponse>> {
    return this.client.get('/network-test/last-result');
  }
  ping(): Promise<AxiosResponse<PingResponse>> {
    return this.client.get('/ping');
  }
}
