import { type AxiosResponse } from 'axios';

import {
  type DtoOneTimePurchaseActivityListResponse,
  type DtoOneTimePurchaseLogListResponse,
  type DtoOneTimePurchaseReceiptResponse,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class OneTimePurchaseAPI extends BaseAPIService {
  listActivities(): Promise<
    AxiosResponse<DtoOneTimePurchaseActivityListResponse>
  > {
    return this.client.get(`/one-time-purchase/activities`);
  }
  queryLogs(): Promise<AxiosResponse<DtoOneTimePurchaseLogListResponse>> {
    return this.client.get(`/one-time-purchase/logs`);
  }
  getReceipt(
    otpLogId: string
  ): Promise<AxiosResponse<DtoOneTimePurchaseReceiptResponse>> {
    return this.client.get(`/one-time-purchase/${otpLogId}/receipt`);
  }
}
