import { type AxiosResponse } from 'axios';

import {
  type DtoFetchDocumentConfigResponse,
  type DtoSearchDocumentResponse,
  type SearchDocsParams,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class AIDocumentAPI extends BaseAPIService {
  fetchConfig(): Promise<AxiosResponse<DtoFetchDocumentConfigResponse>> {
    return this.client.get('/llm/document/fetch-config');
  }

  searchDocs(
    params: SearchDocsParams
  ): Promise<AxiosResponse<DtoSearchDocumentResponse>> {
    return this.client.get('/llm/document/search', {
      params,
    });
  }
}
