import { type AxiosResponse } from 'axios';

import {
  type DtoGetNotificationSettingsResponse,
  type DtoUpdateNotificationSettingsRequest,
  type GetNotificationSettingsParams,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class NotificationAPI extends BaseAPIService {
  GetSettings(
    params: GetNotificationSettingsParams
  ): Promise<AxiosResponse<DtoGetNotificationSettingsResponse>> {
    return this.client.get('/notifications/settings', {
      params,
    });
  }
  UpdateSettings(
    req: DtoUpdateNotificationSettingsRequest
  ): Promise<AxiosResponse> {
    return this.client.post('/notifications/settings', req);
  }
}
