import { type AxiosResponse } from 'axios';

import { type Block } from '@lp-lib/game';

import { type Game, type GameLikeFilters } from '../../types/game';
import { BaseAPIService } from './base.api';
import { type GamePacksResponse } from './gamePack.api';
import { type Paginable, Paginator } from './pagination';

export type CreateGameRequest = {
  name: string;
  description?: string | null;
  coverMediaId?: string | null;
  tags?: string[] | null;
};

export type UpdateGameRequest = Partial<CreateGameRequest>;

export interface GamesResponse extends Paginable {
  games: Game[];
  gamesCount: number;
}

export interface GameResponse {
  game: Game;
}

interface DuplicateGameResponse {
  game: Game;
  blocks: Block[];
}

export class GameAPI extends BaseAPIService {
  create(req: CreateGameRequest): Promise<AxiosResponse<GameResponse>> {
    return this.client.post<GameResponse>('/games', req);
  }

  update(
    id: string,
    req: UpdateGameRequest
  ): Promise<AxiosResponse<GameResponse>> {
    return this.client.put<GameResponse>(`/games/${id}`, req);
  }

  getMyGames(): Paginator<GamesResponse, Game> {
    return new Paginator<GamesResponse, Game>(this.client, '/games', 'games', {
      size: 36,
      config: {
        params: { type: 'my' },
      },
    });
  }

  getGamesByTagId(tagId: number): Paginator<GamesResponse, Game> {
    return new Paginator<GamesResponse, Game>(this.client, '/games', 'games', {
      size: 36,
      config: {
        params: { type: 'tag', key: tagId },
      },
    });
  }

  getUntaggedPrimeGames(): Paginator<GamesResponse, Game> {
    return new Paginator<GamesResponse, Game>(this.client, '/games', 'games', {
      size: 36,
      config: {
        params: { type: 'untagged' },
      },
    });
  }

  searchGames(
    q: string,
    filterParams?: GameLikeFilters,
    primeOnly?: boolean
  ): Paginator<GamesResponse, Game> {
    const encoded = filterParams ? btoa(JSON.stringify(filterParams)) : null;
    return new Paginator<GamesResponse, Game>(
      this.client,
      '/games/search',
      'games',
      {
        size: 36,
        config: {
          params: { q, filters: encoded, primeOnly: !!primeOnly },
        },
      }
    );
  }

  delete(id: string): Promise<AxiosResponse> {
    return this.client.delete(`/games/${id}`);
  }

  duplicate(
    id: string,
    fork?: boolean
  ): Promise<AxiosResponse<DuplicateGameResponse>> {
    return this.client.post<DuplicateGameResponse>(`/games/${id}/duplicate`, {
      fork: !!fork,
    });
  }

  getGameById(id: string): Promise<AxiosResponse<GameResponse>> {
    return this.client.get<GameResponse>(`/games/${id}`);
  }

  publish(
    id: string,
    req: UpdateGameRequest
  ): Promise<AxiosResponse<DuplicateGameResponse>> {
    return this.client.post<DuplicateGameResponse>(`/games/${id}/publish`, req);
  }

  getLinkedGamePacks(id: string): Promise<AxiosResponse<GamePacksResponse>> {
    return this.client.get<GamePacksResponse>(`/games/${id}/game-packs`);
  }
}
